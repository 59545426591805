import { Outlet } from 'react-router-dom';
import { MainLayout } from '@/components/layouts/MainLayout';
import { Tooltip } from '@C/components/common/ui/Tooltip';

export const Main = () => (
    <MainLayout>
        <Tooltip />
        <Outlet />
    </MainLayout>
);
