import { FC } from '@C/types/global';
import { Loading } from '@C/types/data';
import { useIntersectionObserver } from '@C/hooks/intersectionObserver';
import { useEffect, useMemo, useRef } from 'react';
import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { Loader } from '@C/components/common/ui/Loader';
import { Observe, ObservedWrapper } from '@/components/layouts/ObservedLayout/styles';
import { usePrevious } from '@C/hooks/previous';

const useVisibleChanged = (entry?: IntersectionObserverEntry) => {
    const isVisible = !!entry?.isIntersecting;
    const prevVisible = usePrevious(isVisible);

    return { isVisible, isVisibleChanged: isVisible !== prevVisible };
};

interface ObservedLayoutProps extends Loading {
    loadMore: () => void;
    keyValue: number;
}

export const ObservedLayout: FC<ObservedLayoutProps> = ({ children, loadMore, loading, keyValue }) => {
    const key = useMemo(() => keyValue, [keyValue]);
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, { threshold: 0.25, key });

    const { isVisible, isVisibleChanged } = useVisibleChanged(entry);

    useEffect(() => {
        if (isVisible && !loading && isVisibleChanged) {
            loadMore();
        }
    }, [isVisible, loadMore, loading, isVisibleChanged]);

    return (
        <Column width="100%">
            {children}

            <ObservedWrapper width="100%">
                <Observe key={key} ref={ref} />
                {loading && (
                    <Row width="100%" alignCenter justifyCenter>
                        <Loader size="medium" />
                    </Row>
                )}
            </ObservedWrapper>
        </Column>
    );
};
