import { Combobox, ComboboxProps } from '@C/components/common/inputs/Combobox';
import { ComboboxLoadFn } from '@C/components/common/inputs/Combobox/types';
import { useOrdersByIdOrProductNameLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { mapOrdersToOption } from '@/components/inputs/StageCommunicationCombobox/constants';

interface StageCommunicationComboboxProps extends Omit<ComboboxProps, 'defaultOptions' | 'loadFn'> {
    operationId?: number;
    currentProductId?: number;
}

export const StageCommunicationCombobox = ({
    operationId,
    currentProductId,
    ...props
}: StageCommunicationComboboxProps) => {
    const [loadOrders, { data }] = useOrdersByIdOrProductNameLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: {
            skip: 0,
            take: 100,
            name: '',
            operationId,
            currentProductId
        }
    });

    const defaultOrders = mapOrdersToOption(data?.schedules);

    const loadDefaultOrders = () => {
        loadOrders({
            variables: {
                orderId: undefined,
                name: ''
            }
        });
    };

    const loadClientsFn: ComboboxLoadFn = (search, cb) => {
        let variables;
        if (Number(search)) {
            variables = {
                orderId: Number(search),
                name: search
            };
        } else {
            variables = {
                orderId: undefined,
                name: search
            };
        }
        loadOrders({ variables }).then(({ data }) => cb(mapOrdersToOption(data?.schedules)));
    };

    const onBlur = () => {
        loadDefaultOrders();
    };

    const onInputChange = (newValue: string) => {
        if (newValue === '') {
            loadDefaultOrders();
        }
    };

    useEffect(() => {
        loadOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Combobox
            onBlur={onBlur}
            onInputChange={onInputChange}
            defaultOptions={defaultOrders}
            loadFn={loadClientsFn}
            {...props}
        />
    );
};
