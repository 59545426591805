import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { useStatusesLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { ReactSelectOption } from '@C/types/data';
import { ButtonSelector, ButtonSelectorProps } from '@C/components/common/inputs/ButtonSelect';

const useStatusOptions = () => {
    const [loadStatuses, { data }] = useStatusesLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });

    const options: ReactSelectOption[] = (data?.orderStatuses || []).map(({ id, name }) => ({
        label: name,
        value: id.toString()
    }));

    useEffect(() => {
        loadStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return options;
};

type StatusSelectorProps = Omit<SelectorProps<true>, 'options'>;

export const StatusSelector = (props: StatusSelectorProps) => {
    const options = useStatusOptions();

    return <Selector<true> options={options} {...props} />;
};

type ButtonStatusSelectorProps = Omit<ButtonSelectorProps<true>, 'options'>;

export const ButtonStatusSelector = (props: ButtonStatusSelectorProps) => {
    const options = useStatusOptions();

    return <ButtonSelector options={options} {...props} />;
};
