import { IconType, StatusBlockIcon, StatusDoneIcon, StatusExecutionIcon } from '@C/assets/icons';
import { softGray } from '@C/constants/styles/colors';

export const iconByStatusMapper: { [key in number]: IconType } = {
    2: StatusExecutionIcon,
    3: StatusExecutionIcon,
    4: StatusDoneIcon,
    5: StatusDoneIcon,
    6: StatusBlockIcon,
    7: StatusExecutionIcon
};

export const colorByStatusMapper: { [key in number]: string } = {
    5: softGray,
    6: softGray
};
