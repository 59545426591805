import { Router, RouterProps } from 'react-router-dom';
import { History } from 'history';
import { useLayoutEffect, useState } from 'react';

interface CustomRouterProps extends Pick<RouterProps, 'basename' | 'children'> {
    history: History;
}

export const CustomRouter = ({ basename, children, history }: CustomRouterProps) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
            {children}
        </Router>
    );
};
