import { combine, createEvent, createStore } from 'effector';
import { OrdersQueryVariables } from '@/graphql/api.types';
import { ReactSelectOption } from '@C/types/data';
import { OnChangePeriod, PeriodDateType } from '@C/components/common/inputs/Datepicker/types';
import { Moment } from 'moment';
import { OnTextChange } from '@C/components/common/inputs/TextField/types';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';
import { defaultTake } from '@/constants/store';
import { OnSortChange, SortState } from '@C/components/common/ui/tables/Table/types';
import { OrdersTableData } from '@/components/tables/OrdersTable/types';
import { getOrderByColumn } from '@/components/tables/OrdersTable/constants';

export interface OrdersFilterValues {
    status?: ReactSelectOption[] | null;
    period?: PeriodDateType<Moment> | null;
    clientId?: ReactSelectOption | null;
    search?: string;
    orderByState?: SortState<OrdersTableData>;
}

export interface OnChangeFilterValues {
    onStatusChange: OnSelectChange<true>;
    onPeriodChange: OnChangePeriod<Moment>;
    onClientIdChange: OnSelectChange;
    onSearchChange: OnTextChange;
    onOrderByChange: OnSortChange<OrdersTableData>;
}

const initialValues: OrdersFilterValues = {
    search: '',
    period: undefined,
    clientId: undefined,
    status: undefined,
    orderByState: { id: 'id', sortState: 'asc' }
};

const initialQueryParams: OrdersQueryVariables = {
    take: defaultTake,
    skip: 0,
    orderBy: getOrderByColumn(initialValues.orderByState)
};

export const setOrdersFilterValue = createEvent<OrdersFilterValues>();
export const clearOrdersFilter = createEvent();
export const setOrdersQueryParams = createEvent<OrdersQueryVariables>();

const ordersFilterValuesStore = createStore<OrdersFilterValues>(initialValues)
    .on(setOrdersFilterValue, (state, newState) => ({ ...state, ...newState }))
    .on(clearOrdersFilter, () => ({ ...initialValues }));

const ordersQueryParamsStore = createStore<OrdersQueryVariables>(initialQueryParams).on(
    setOrdersQueryParams,
    (_, newQueryParams) => newQueryParams
);

ordersFilterValuesStore.watch(({ search, period, status, clientId, orderByState }) => {
    const [periodStart, periodEnd] = period || [],
        statusValue = status || [],
        newQueryParams = {
            ...initialQueryParams,
            periodEnd: periodEnd?.toISOString(),
            periodStart: periodStart?.toISOString(),
            search,
            status: statusValue?.length > 0 ? statusValue?.map(({ label }) => label) : undefined,
            clientId: Number(clientId?.value) || undefined,
            orderBy: getOrderByColumn(orderByState)
        };

    setOrdersQueryParams(newQueryParams);
});

const onChangeValuesStore = createStore<OnChangeFilterValues>({
    onClientIdChange: clientId => setOrdersFilterValue({ clientId }),
    onPeriodChange: period => setOrdersFilterValue({ period }),
    onSearchChange: ({ target: { value: search } }) => setOrdersFilterValue({ search }),
    onStatusChange: status => setOrdersFilterValue({ status: status as ReactSelectOption[] }),
    onOrderByChange: orderByState => setOrdersFilterValue({ orderByState })
});

export const ordersFilterStore = combine(ordersFilterValuesStore, ordersQueryParamsStore, onChangeValuesStore);
