import {
    clearOrdersFilter,
    ordersFilterStore,
    setOrdersFilterValue,
    setOrdersQueryParams
} from '@/stores/filters/ordersFilter';
import {
    clearClientsFilter,
    clientsFilterStore,
    setClientsFilterValue,
    setClientsQueryParams
} from '@/stores/filters/clientsFilter';
import { clearSchedulerFilter, schedulerFilterStore, setSchedulerFilterValue } from '@/stores/filters/schedulerFilter';
import {
    clearOrdersOnSchedulerFilter,
    ordersOnSchedulerFilterStore,
    setOrdersOnSchedulerFilterValue,
    setOrdersOnSchedulerQueryParams
} from '@/stores/filters/ordersOnSchedulerFilter';

export const filterStores = {
    ordersFilterStore,
    clientsFilterStore,
    schedulerFilterStore,
    ordersOnSchedulerFilterStore
};

export const filterEvents = {
    setOrdersFilterValue,
    setOrdersQueryParams,
    clearOrdersFilter,
    clearClientsFilter,
    setClientsFilterValue,
    setClientsQueryParams,
    clearSchedulerFilter,
    setSchedulerFilterValue,
    clearOrdersOnSchedulerFilter,
    setOrdersOnSchedulerFilterValue,
    setOrdersOnSchedulerQueryParams
};
