import { Table } from '@C/components/common/ui/tables/Table';
import { columns } from '@/components/tables/ClientsTable/constants';
import { useMemo } from 'react';
import { ClientsTableData } from '@/components/tables/ClientsTable/types';
import { ClientsQuery } from '@/graphql/api.types';
import { onRowClickType } from '@C/components/common/ui/tables/Table/types';
import { AddressRow } from '@/components/tables/ClientsTable/styles';

interface OrdersTableProps {
    data?: ClientsQuery['clients'];
    onClientClick: (id: number) => void;
}

export const ClientsTable = ({ data = [], onClientClick }: OrdersTableProps) => {
    const tableData: ClientsTableData[] = useMemo(
        () => data?.map(({ address, ...data }) => ({ ...data, address: <AddressRow>{address}</AddressRow> })),
        [data]
    );

    const onRowClick: onRowClickType<ClientsTableData> = row => onClientClick(row.id);

    return <Table<ClientsTableData> columns={columns} data={tableData} onRowClick={onRowClick} withSticky />;
};
