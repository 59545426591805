import { ApolloClient, createHttpLink, FieldMergeFunction, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getUserData } from '@/utils/auth';
import { REACT_APP_API_URL } from '@/constants/common';

const httpLink = createHttpLink({
    uri: REACT_APP_API_URL
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const userData = getUserData();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: userData?.token ? `Bearer ${userData?.token}` : ''
        }
    };
});

const merge: FieldMergeFunction = (existing, incoming, { args }) => {
    const merged = existing ? existing.slice(0) : [];
    if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { skip = 0 } = args;
        for (let i = 0; i < incoming.length; ++i) {
            merged[skip + i] = incoming[i];
        }
    } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        // eslint-disable-next-line prefer-spread
        merged.push.apply(merged, incoming);
    }
    return merged;
};

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    orders: {
                        keyArgs: ['$search', '$orderBy', '$periodStart', '$periodEnd', '$status', '$clientId'],
                        merge
                    },
                    clients: {
                        keyArgs: ['$search', '$orderBy'],
                        merge: merge
                    }
                }
            }
        }
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        }
    }
});
