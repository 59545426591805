import React from 'react';
import { GlobalStyle } from '@/constants/styles/global';
import './App.css';
import { CustomRouter } from '@/router/CustomRouter';
import { history } from '@/browserHistory';
import { Routes } from '@/router/Routes';
import { AppModals } from '@/components/modals/AppModals';
import { Notifications } from '@C/components/common/ui/Notifications';

function App() {
    return (
        <>
            <GlobalStyle />
            <AppModals />
            <Notifications />
            <CustomRouter history={history}>
                <Routes />
            </CustomRouter>
        </>
    );
}

export default App;
