import * as yup from 'yup';
import { yupDefault, yupReactSelectStrict } from '@/constants/validation/yupFields';

export const statusMeta = {
    status: {
        label: 'Статус',
        placeholder: 'Выберите статус'
    },
    comment: {
        label: 'Комментарий',
        placeholder: 'Введите комментарий'
    }
};

export const yupSchema = yup.object({
    status: yupReactSelectStrict,
    comment: yupDefault
});
