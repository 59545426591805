import { primaryColor } from '@C/constants/styles/colors';
import { Column, FlexGrow } from '@C/components/wrappers/grid/FlexWrapper';
import styled from 'styled-components';
import { Information } from '@C/components/common/blocks/Information/styles';

export const TitleBlock = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    padding: 10px 15px;
    color: ${primaryColor};
`;

export const ContentColumn = styled(FlexGrow)`
    flex: 1 0 0;
`;

export const ClientWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0 0 20px 0;

    ${ContentColumn} {
        margin: 0 8px;

        :first-child {
            margin-left: 0;
        }

        :last-child {
            margin-right: 0;
        }
    }
`;

export const InformationWrapper = styled(Column)`
    width: 100%;

    ${Information} {
        margin-bottom: 4px;
    }
`;
