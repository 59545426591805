import { FC } from '@C/types/global';
import { MainContent, MainLayoutWrapper } from '@/components/layouts/MainLayout/styles';
import { MainHeader } from '@/components/grid/Headers/MainHeader';
import { matchPath, useLocation } from 'react-router';
import { to } from '@/router/routerNames';
import { history } from '@/browserHistory';
import { getUserData, logout } from '@/utils/auth';

export const MainLayout: FC<object> = ({ children }) => {
    const { pathname } = useLocation();
    const userData = getUserData();

    const isActive = (path: string) => !!matchPath({ path }, pathname);

    const onItemClick = (path: string) => history.push(to(path));

    const onLogout = () => logout();

    return (
        <MainLayoutWrapper>
            <MainHeader
                userName={userData!.user.name || 'No name'}
                onLogout={onLogout}
                onItemClick={onItemClick}
                isActive={isActive}
            />
            <MainContent>{children}</MainContent>
        </MainLayoutWrapper>
    );
};
