import styled from 'styled-components';
import { lightGrey } from '@C/constants/styles/colors';
import { headerHeight, headerHorizontalPadding } from '@/components/grid/Headers/MainHeader/constants';
import { containerSize } from '@C/constants/styles/breackpoints';

export const Header = styled.header`
    height: ${headerHeight};
    background-color: ${lightGrey};
`;

export const HeaderContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: ${containerSize.xxxl}px;
    margin: 0 auto;
    height: 100%;
`;

export const LogoWrapper = styled.div`
    position: absolute;
    left: ${headerHorizontalPadding};
    height: 100%;
    display: flex;
    align-items: center;
    img {
        width: 120px;
    }
`;
