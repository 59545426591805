import { OrderOptions } from '@/components/contents/Order/OrderContent/types';

export const orderOptions: (isLastProduct: boolean, isCanceled?: boolean) => OrderOptions[] = (
    isLastProduct,
    isCanceled
) => [
    { value: 'copy', label: 'Дублировать заказ' },
    { value: 'changeOrder', label: 'Изменить заказ', isDisabled: isCanceled },
    { value: 'changeProduct', label: 'Изменить изделие', isDisabled: isCanceled },
    { value: 'delete', label: 'Удалить изделие', isDisabled: isLastProduct || isCanceled },
    { value: 'cancelOrder', label: 'Отменить заказ', isDisabled: isCanceled },
    { value: 'exportProduct', label: 'Экспорт изделия' }
];

export const defaultOrderFormModalParams = {
    minWidth: '700px'
};

export const buttonsTop = '6px';
