import { userAuthName } from '@/constants/localStorage';
import { SignInMutation } from '@/graphql/api.types';
import { history } from '@/browserHistory';
import { loginRoute, to } from '@/router/routerNames';

type UserAuthType = SignInMutation['signIn'];

export const setUserData = (userAuth: UserAuthType) => localStorage.setItem(userAuthName, JSON.stringify(userAuth));

export const getUserData = () => {
    const userData = localStorage.getItem(userAuthName);

    return userData ? (JSON.parse(userData) as UserAuthType) : null;
};

export const logout = () => {
    localStorage.removeItem(userAuthName);
    history.push(to(loginRoute));
};
