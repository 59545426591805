import { useEffect, useId, useState } from 'react';
import { Form, FormInputs, InputWrapper } from '@/components/forms/MaterialForm/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@C/components/common/inputs/TextField';
import { materialMeta, yupSchema } from '@/components/forms/MaterialForm/constants';
import { NoopType, OnUpdateInterface } from '@C/types/data';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaterialsQuery, useCreateMaterialMutation, useUpdateMaterialMutation } from '@/graphql/api.types';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';
import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { MaterialInputs } from '@/components/forms/MaterialForm/types';

interface MaterialFormProps extends OnUpdateInterface {
    onClose: NoopType;
    type?: 'new' | 'edit' | 'copy';
    material?: MaterialsQuery['materials'][0];
}

export const MaterialForm = ({ onClose, type = 'new', onUpdate, material }: MaterialFormProps) => {
    const isNew = type === 'new',
        isEdit = type === 'edit',
        isCopy = type === 'copy';

    const id = useId();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isValid, errors }
    } = useForm<MaterialInputs>({ resolver: yupResolver(yupSchema), mode: 'onChange' });

    const [createMaterial] = useCreateMaterialMutation();
    const [updateMaterial] = useUpdateMaterialMutation();

    const onSubmit: SubmitHandler<MaterialInputs> = async ({ name, density, format }) => {
        try {
            setLoading(true);

            if (isNew || isCopy) {
                const returnedCreateMaterial = await createMaterial({
                    variables: {
                        name,
                        density,
                        format
                    }
                });

                notify.success(
                    <NotifyContent
                        title="Материал успешно создан"
                        text={
                            <>
                                Материал № <b>{returnedCreateMaterial.data?.createMaterial?.id || ''}</b>{' '}
                                {returnedCreateMaterial.data?.createMaterial?.alias} создан
                            </>
                        }
                    />
                );
            }

            if (isEdit && material?.id) {
                await updateMaterial({
                    variables: {
                        id: material?.id,
                        name,
                        density,
                        format
                    }
                });

                notify.info(
                    <NotifyContent
                        title="Материал успешно изменен"
                        text={
                            <>
                                Материал № <b>{material?.id || ''}</b> изменен
                            </>
                        }
                    />
                );
            }

            onClose();
        } catch (e) {
            notify.error(<NotifyContent title="Что-то пошло не так" />);
        } finally {
            onUpdate && onUpdate();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (material) {
            const { name, density, format } = material;

            if (isEdit || isCopy) {
                name && setValue('name', name);
                density && setValue('density', density);
                format && setValue('format', format);
            }
        }
    }, [material, isEdit, isCopy, setValue]);

    return (
        <Form>
            <FormInputs>
                <Column width="100%">
                    <InputWrapper>
                        <TextField
                            type="text"
                            id={`${id}-name`}
                            label={materialMeta.name.label}
                            placeholder={materialMeta.name.placeholder}
                            errorText={errors.name?.message}
                            // @ts-ignore
                            {...register('name')}
                            required
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TextField
                            type="text"
                            id={`${id}-density`}
                            label={materialMeta.density.label}
                            placeholder={materialMeta.density.placeholder}
                            errorText={errors.density?.message}
                            // @ts-ignore
                            {...register('density')}
                            required
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TextField
                            type="text"
                            id={`${id}-format`}
                            label={materialMeta.format.label}
                            placeholder={materialMeta.format.placeholder}
                            errorText={errors.format?.message}
                            // @ts-ignore
                            {...register('format')}
                            required
                        />
                    </InputWrapper>
                </Column>
            </FormInputs>

            <Row width="100%" justifyEnd>
                <MarginWrapper marginRight="10px">
                    <Button onClick={onClose} colorTheme="secondary">
                        Отмена
                    </Button>
                </MarginWrapper>
                <Button disabled={!isValid} loading={loading} onClick={handleSubmit(onSubmit)}>
                    {isEdit ? 'Сохранить' : 'Готово'}
                </Button>
            </Row>
        </Form>
    );
};
