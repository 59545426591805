import { TextField } from '@C/components/common/inputs/TextField';
import { useId } from 'react';
import { InputWrapper } from '@/components/layouts/FilterLayout/styles';
import { FilterLayout } from '@/components/layouts/FilterLayout';
import { CreateClick } from '@/components/layouts/FilterLayout/types';
import { OnChangeFilterValues, ClientsFilterValues } from '@/stores/filters/clientsFilter';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { DeleteIcon } from '@C/assets/icons';
import { filterEvents } from '@/stores/filters';

const { clearClientsFilter } = filterEvents;

interface ClientFilterProps extends CreateClick {
    values: ClientsFilterValues;
    onChanges: OnChangeFilterValues;
}

export const ClientFilter = ({ onCreateClick, values, onChanges }: ClientFilterProps) => {
    const id = useId();

    const { onSearchChange } = onChanges;
    const { search } = values;

    return (
        <FilterLayout onCreateClick={onCreateClick}>
            <InputWrapper removeMarginRight>
                <TextField
                    onChange={onSearchChange}
                    value={search}
                    placeholder="Поиск"
                    inputTheme="secondary"
                    id={`${id}-search`}
                />
            </InputWrapper>
            <MarginWrapper marginLeft="10px">
                <Button onClick={clearClientsFilter} icon={<DeleteIcon />} colorTheme="secondary" />
            </MarginWrapper>
        </FilterLayout>
    );
};
