import printValue from 'yup/lib/util/printValue';
import {
    ArrayLocale,
    BooleanLocale,
    DateLocale,
    LocaleObject,
    MixedLocale,
    NumberLocale,
    ObjectLocale,
    StringLocale
} from 'yup/lib/locale';

const mixed: MixedLocale = {
    default: 'Содержит ошибку',
    required: 'Обязательно для заполнения',
    defined: '${path} должен быть определен',
    oneOf: 'Должен содержать одно из следующих значение: ${values}',
    notOneOf: 'Не должен содержать одно из следующих значение: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        const isCast = originalValue != null && originalValue !== value;
        let msg =
            `${path} должен быть \`${type}\` типом, ` +
            `но финальное значение: \`${printValue(value, true)}\`` +
            (isCast ? ` (приведено из значения \`${printValue(originalValue, true)}\`).` : '.');

        if (value === null) {
            msg += `\n Если "null" является пустым значением, убедитесь что схема помечена как \`.nullable()\``;
        }

        return msg;
    }
};

const string: StringLocale = {
    length: 'Длина должна иметь ${length} символов',
    min: 'Должен содержать минимум ${min} символов',
    max: 'Должен содержать не более ${max} символов',
    matches: '${path} должен совпадать со следующим регулярном выражением: "${regex}"',
    email: 'Должен быть email',
    url: 'Значение должно быть валидной ссылкой',
    uuid: 'Значение должно быть валидными UUID',
    trim: 'Поле не должно содержать в начале или в конце пробелы',
    lowercase: 'Значение должно быть в нижним регистре',
    uppercase: 'Значение должно быть в верхнем регистре'
};

const number: NumberLocale = {
    min: 'Значение должно быть больше или равно ${min}',
    max: 'Значение должно быть меньше или равно ${max}',
    lessThan: 'Значение должно быть меньше чем ${less}',
    moreThan: 'Значение должно быть больше ${more}',
    positive: 'Значение должно быть положительном числом',
    negative: 'Значение должно быть негативном числом',
    integer: 'Значение должно быть целым числом'
};

const date: DateLocale = {
    min: 'Дата не может быть меньше начальной',
    max: 'Дата не может быть больше конечной'
};

const boolean: BooleanLocale = {
    isValue: 'Должно иметь значение: ${value}'
};

const object: ObjectLocale = {
    noUnknown: '${path} field cannot have keys not specified in the object shape'
};

const array: ArrayLocale = {
    min: 'В поле должно быть указано не менее ${min} элементов',
    max: 'В поле должно быть указано не более ${max} элементов',
    length: 'Должен иметь ${length} элементов'
};

export const ru: LocaleObject = {
    mixed,
    date,
    number,
    string,
    boolean,
    array,
    object
};
