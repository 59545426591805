import { LogoImage } from '@C/assets/images';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { TextField } from '@C/components/common/inputs/TextField';
import { Button } from '@C/components/common/buttons/Button';
import { AbsoluteWrapper } from '@C/components/wrappers/grid/AbsoluteWrapper';
import { Form, InfoBadge } from '@/components/forms/AuthForm/styles';
import { useId } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthInputs } from '@/components/forms/AuthForm/types';
import { useSignInMutation } from '@/graphql/api.types';
import { setUserData } from '@/utils/auth';
import { history } from '@/browserHistory';
import { defaultRoute } from '@/router/routerNames';

export const AuthForm = () => {
    const id = useId();
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm<AuthInputs>();

    const [signIn, { loading }] = useSignInMutation({
        onCompleted: ({ signIn }) => {
            setUserData(signIn);
            history.push(defaultRoute);
        }
    });

    const onSubmit: SubmitHandler<AuthInputs> = async ({ login, password }) => {
        try {
            await signIn({ variables: { login, password } });
            clearErrors();
        } catch (e) {
            setError('login', { type: 'manual', message: 'Неправильно введен логин или пароль' });
            setError('password', { type: 'manual', message: 'Неправильно введен логин или пароль' });
        }
    };

    const hasError = !!errors.password || !!errors.login;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <AbsoluteWrapper top="-150px">
                <LogoImage />
            </AbsoluteWrapper>
            <InfoBadge hasError={hasError}>
                {hasError ? errors.login?.message || errors.password?.message : 'Введите свои учетные данные'}
            </InfoBadge>
            <Row marginTop="30px" marginBottom="15px" width="100%">
                <TextField
                    hasError={hasError}
                    placeholder="Логин"
                    id={`${id}-login`}
                    type="text"
                    {...register('login')}
                />
            </Row>
            <Row marginBottom="30px" width="100%">
                <TextField
                    hasError={hasError}
                    placeholder="Пароль"
                    id={`${id}-password`}
                    type="password"
                    {...register('password')}
                />
            </Row>
            <Button loading={loading} type="submit">
                Войти
            </Button>
        </Form>
    );
};
