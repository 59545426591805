import { PageLayout } from '@/components/layouts/PageLayout';
import { OrderFilter } from '@/components/filters/OrderFilter';
import { history } from '@/browserHistory';
import { ordersRoute, to, toNew } from '@/router/routerNames';
import { Column } from '@C/components/wrappers/grid/FlexWrapper';
import { OrdersTable } from '@/components/tables/OrdersTable';
import { useExportProductsMutation, useOrdersQuery } from '@/graphql/api.types';
import { Outlet } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { filterStores } from '@/stores/filters';
import { ObservedLayout } from '@/components/layouts/ObservedLayout';
import { useDebounce } from '@C/hooks/debounce';
import { useLocation } from 'react-router';
import { BACKEND_URL } from '@/constants/common';
import { goToHrefLocation } from '@/utils/browser';

export const Orders = () => {
    const [filterValues, queryParams, onChanges] = useStore(filterStores.ordersFilterStore);
    const { pathname } = useLocation();

    const debouncedSearch = useDebounce(queryParams.search, 500);
    const searchVariables = useMemo(
        () => ({
            orderId: Number(debouncedSearch) || undefined,
            search: debouncedSearch
        }),
        [debouncedSearch]
    );
    const queryVariables = useMemo(() => ({ ...queryParams, ...searchVariables }), [queryParams, searchVariables]);

    const {
        data: ordersData,
        fetchMore,
        loading,
        client: { cache }
    } = useOrdersQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: { ...queryVariables }
    });

    const orders = ordersData?.products || [];

    const [exportProducts] = useExportProductsMutation();

    const loadMore = () => {
        fetchMore({ variables: { ...queryVariables, skip: orders.length } });
    };

    const onCreateClick = () => history.push(toNew(ordersRoute));
    const onOrderClick = (id: number) => history.push(to(ordersRoute) + to(String(id)));

    const onLoad = useCallback(async () => {
        const exportProductsData = await exportProducts(),
            productsFilePath = exportProductsData?.data?.exportProducts?.filePath;

        goToHrefLocation(BACKEND_URL, productsFilePath);
    }, [exportProducts]);

    const filter = useMemo(
        () => <OrderFilter onChanges={onChanges} values={filterValues} onCreateClick={onCreateClick} onLoad={onLoad} />,
        [filterValues, onChanges, onLoad]
    );

    useEffect(() => {
        if (pathname === to(ordersRoute)) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
            fetchMore({ variables: { ...queryVariables, skip: 0 } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <PageLayout title="Изделия" filter={filter}>
            <ObservedLayout loading={loading} loadMore={loadMore} keyValue={orders.length}>
                <Column width="100%" marginBottom="20px">
                    <OrdersTable
                        onOrderByChange={onChanges.onOrderByChange}
                        orderByState={filterValues.orderByState}
                        onOrderClick={onOrderClick}
                        data={orders}
                    />
                </Column>
            </ObservedLayout>
            <Outlet />
        </PageLayout>
    );
};
