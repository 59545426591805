import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { Breadcrumb, BreadcrumbItem } from '@C/components/common/ui/Breadcrumb';
import React, { useCallback, useMemo } from 'react';
import { ReactChildren } from '@C/types/global';
import { useLocation } from 'react-router';
import { routeNames, to } from '@/router/routerNames';
import { Link } from 'react-router-dom';

type BreadcrumbLayoutProps = ReactChildren;

export const BreadcrumbLayout = ({ children }: BreadcrumbLayoutProps) => {
    const { pathname } = useLocation();
    const path = useMemo(
        () =>
            pathname
                .split('/')
                .filter(value => !!value)
                .map(title => ({
                    title: routeNames[title] || `№${title}`,
                    linkName: title
                })),
        [pathname]
    );

    const toRouteFn = useCallback(
        (index: number) =>
            path
                .slice(0, index + 1)
                .map(({ linkName }) => to(linkName))
                .join(''),
        [path]
    );
    return (
        <Column width="100%">
            <Row marginBottom="15px">
                <Breadcrumb>
                    {path.map(({ title }, index) => (
                        <BreadcrumbItem active={index === path.length - 1} key={title}>
                            <Link to={toRouteFn(index)}>{title}</Link>
                        </BreadcrumbItem>
                    ))}
                </Breadcrumb>
            </Row>
            <Column width="100%">{children}</Column>
        </Column>
    );
};
