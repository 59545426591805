import { BreadcrumbLayout } from '@/components/layouts/BreadcrumbLayout';
import { Title } from '@/pages/Clients/Client/styles';
import { ControlPageButtons } from '@/components/grid/ControlPageButtons';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { useStore } from 'effector-react';
import { filterStores } from '@/stores/filters';
import { ClientsQuery, useClientByIdLazyQuery, useClientsQuery } from '@/graphql/api.types';
import { useParams } from 'react-router-dom';
import { ClientParams } from '@/router/types';
import { useAround } from '@/hooks/around';
import { clientsRoute, to } from '@/router/routerNames';
import { useCallback, useEffect } from 'react';
import { Loader } from '@C/components/common/ui/Loader';
import { ClientContent } from '@/components/contents/Client/ClientContent';
import { titleLineHeight } from '@/pages/Orders/Order/constants';

export const Client = () => {
    const [, queryParams] = useStore(filterStores.clientsFilterStore);
    const { data: clientsData } = useClientsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-only',
        variables: { ...queryParams }
    });

    const { clientId } = useParams<ClientParams>();

    const { onNext, onPrevious } = useAround<ClientsQuery['clients'][0]>(
        clientId,
        clientsData?.clients,
        to(clientsRoute)
    );

    const [loadClientById, { data, loading, refetch }] = useClientByIdLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite'
    });

    const onUpdate = useCallback(() => refetch(), [refetch]);

    useEffect(() => {
        loadClientById({ variables: { id: Number(clientId) } });
    }, [loadClientById, clientId]);

    return (
        <BreadcrumbLayout>
            <Row width="100%" justifyBetween alignCenter marginBottom="40px">
                <Row height={titleLineHeight}>
                    <Title>{data?.client?.name}</Title>
                </Row>
                <Row>
                    <ControlPageButtons onNext={onNext} onPrevious={onPrevious} />
                </Row>
            </Row>
            <Row width="100%">
                {loading ? (
                    <Row width="100%" justifyCenter>
                        <Loader />
                    </Row>
                ) : (
                    <ClientContent onUpdate={onUpdate} client={data?.client} />
                )}
            </Row>
        </BreadcrumbLayout>
    );
};
