import { OrderMeta } from '@/components/forms/OrderForm/types';
import * as yup from 'yup';
import {
    yupBooleanNullable,
    yupDefault,
    yupNumberPositive,
    yupNumberStrictPositive,
    yupReactSelect,
    yupReactSelectStrict,
    yupString
} from '@/constants/validation/yupFields';

export const orderMeta: OrderMeta = {
    productName: {
        label: 'Наименование изделия',
        placeholder: 'Наименование изделия'
    },
    orderName: {
        label: 'Наименование заказа',
        placeholder: 'Наименование заказа'
    },
    clientId: {
        label: 'Заказчик',
        placeholder: 'Заказчик'
    },
    machineId: {
        label: 'Печатное оборудование',
        placeholder: 'Печатное оборудование'
    },
    size: {
        label: 'Размер готового изделия',
        placeholder: 'Размер готового изделия'
    },
    batchSize: {
        label: 'Тираж, шт',
        placeholder: 'Тираж'
    },
    materialId: {
        label: 'Материал (Н/П/Ф)',
        placeholder: 'Материал (Н/П/Ф)'
    },
    address: {
        label: 'Адрес',
        placeholder: 'Адрес'
    },
    delivery: {
        label: 'Доставка',
        placeholder: 'Доставка'
    },
    fitting: {
        label: 'Приладка, шт',
        placeholder: 'Приладка'
    },
    flatShare: {
        label: 'Доля флата, шт',
        placeholder: 'Доля флата'
    },
    printFormat: {
        label: 'Формат печати',
        placeholder: 'Формат печати'
    },
    colorfulness: {
        label: 'Красочность',
        placeholder: 'Красочность'
    },
    varnish: {
        label: 'Лаковое покрытие',
        placeholder: 'Лаковое покрытие'
    },
    productsOnTheStampCount: {
        label: 'Количество изделий на штампе, шт',
        placeholder: 'Количество изделий на штампе'
    },
    stampNumber: {
        label: 'Номер штампа',
        placeholder: 'Номер штампа'
    },
    piecesPerPrintedSheetCount: {
        label: 'Количество штук на печатном листе, шт',
        placeholder: 'Количество штук на печатном листе'
    },
    deadlineAt: {
        label: 'Дата сдачи заказа',
        placeholder: 'Дата сдачи заказа'
    },
    productType: {
        label: 'Тип изделия',
        placeholder: 'Тип изделия'
    },
    description: {
        label: 'Описание',
        placeholder: 'Описание'
    },
    filmType: {
        label: 'Вид фольги (Тиснение)',
        placeholder: 'Вид фольги (Тиснение)'
    },
    laminatedProductsPerSheetCount: {
        label: 'Количество изделий на листе (Ламинация), шт',
        placeholder: 'Количество изделий на листе (Ламинация)'
    },
    laminationSheetLength: {
        label: 'Длина листа ламинации',
        placeholder: 'Длина листа ламинации'
    },
    typeOfFoil: {
        label: 'Вид пленки',
        placeholder: 'Вид пленки'
    },
    piecesPerSilkscreenSheetCount: {
        label: 'Количество изделий на листе для шелкографии',
        placeholder: 'Количество изделий на листе для шелкографии'
    },
    itemsPerSheetEmbossingCount: {
        label: 'Количество изделий на листе (Тиснение), шт',
        placeholder: 'Количество изделий на листе (Тиснение)'
    },
    embossedSheetLength: {
        label: 'Длина листа на тиснении',
        placeholder: 'Длина листа на тиснении'
    },
    gluingPointsCount: {
        label: 'Количество точек склейки, шт',
        placeholder: 'Количество точек склейки'
    },
    dieCuttingPositionsCount: {
        label: 'Количество позиций для высечки, шт',
        placeholder: 'Количество позиций для высечки, шт'
    },
    itemsPerSheetForSolidUVVarnishCount: {
        label: 'Количество изделий на листе для УФ-лака сплошного, шт',
        placeholder: 'Количество изделий на листе для УФ-лака сплошного, шт'
    }
};

export const yupSchema = yup.object({
    productName: yupDefault,
    orderName: yupDefault,
    clientId: yupReactSelectStrict,
    machineId: yupReactSelect,
    size: yupReactSelectStrict,
    batchSize: yupNumberStrictPositive,
    materialId: yupReactSelect,
    address: yupString,
    delivery: yupReactSelectStrict,
    fitting: yupNumberPositive,
    flatShare: yupNumberPositive.min(1).max(6),
    printFormat: yupString,
    colorfulness: yupString,
    varnish: yupBooleanNullable,
    productsOnTheStampCount: yupNumberPositive,
    stampNumber: yupString,
    piecesPerPrintedSheetCount: yupNumberPositive,
    deadlineAt: yupDefault,
    productType: yupReactSelectStrict,
    description: yupString,
    filmType: yupReactSelect,
    laminatedProductsPerSheetCount: yupNumberPositive,
    laminationSheetLength: yupNumberPositive,
    typeOfFoil: yupReactSelect,
    piecesPerSilkscreenSheetCount: yupNumberPositive,
    itemsPerSheetEmbossingCount: yupNumberPositive,
    embossedSheetLength: yupNumberPositive,
    gluingPointsCount: yupNumberPositive,
    dieCuttingPositionsCount: yupNumberPositive,
    itemsPerSheetForSolidUVVarnishCount: yupNumberPositive
});
