import { ConfirmationModalProps } from '@C/components/common/modals/ConfirmationModal';
import { NoopFn } from '@/constants/common';
import { modalFabric } from './modal';
import { OkAsyncProps } from '@/stores/modals/buttonsModal';

interface ConfirmationModalStore extends Omit<ConfirmationModalProps, 'loading' | 'onOk'>, OkAsyncProps {}

const initialStore: ConfirmationModalStore = {
    isOpen: false,
    onClose: NoopFn,
    onOk: NoopFn,
    onError: NoopFn,
    titleText: '',
    cancelText: '',
    confirmationText: '',
    okText: '',
    okDisabled: false
};

export const {
    modal: confirmationModal,
    openModal: openConfirmationModal,
    setModal: setConfirmationModal,
    closeModal: closeConfirmationModal
} = modalFabric<ConfirmationModalStore>(initialStore);
