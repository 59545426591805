import styled from 'styled-components';

export const OrderContentWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

export const AddButtonWrapper = styled.div`
    svg {
        height: 15px !important;
    }
`;
