import { ConfirmationModal as OriginConfirmationModal } from '@C/components/common/modals/ConfirmationModal';
import { useStore } from 'effector-react';
import { modalStores } from '@/stores/modals';
import { useOnOkModal } from '@/hooks/onOkModal';

export const ConfirmationModal = () => {
    const { onOk, onError, ...confirmationModalProps } = useStore(modalStores.confirmationModal);

    const { isLoading, onOkFn } = useOnOkModal({ onOk, onError });

    return <OriginConfirmationModal loading={isLoading} onOk={onOkFn} {...confirmationModalProps} />;
};
