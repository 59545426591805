import { PageLayout } from '@/components/layouts/PageLayout';
import { ClientFilter } from '@/components/filters/ClientFilter';
import { history } from '@/browserHistory';
import { clientsRoute, toNew } from '@/router/routerNames';
import { useClientsQuery } from '@/graphql/api.types';
import { ObservedLayout } from '@/components/layouts/ObservedLayout';
import { Column } from '@C/components/wrappers/grid/FlexWrapper';
import { useMemo } from 'react';
import { ClientsTable } from '@/components/tables/ClientsTable';
import { useStore } from 'effector-react';
import { filterStores } from '@/stores/filters';
import { useDebounce } from '@C/hooks/debounce';
import { Outlet } from 'react-router-dom';
import { toClientRoute } from '@/router/routerFn';

export const Clients = () => {
    const [filterValues, queryParams, onChanges] = useStore(filterStores.clientsFilterStore);

    const debouncedSearch = useDebounce(queryParams.search, 500);
    const queryVariables = useMemo(() => ({ ...queryParams, search: debouncedSearch }), [queryParams, debouncedSearch]);

    const {
        loading,
        data: clientsData,
        fetchMore
    } = useClientsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: { ...queryVariables }
    });

    const clients = clientsData?.clients || [];

    const loadMore = () => {
        fetchMore({ variables: { ...queryVariables, skip: clients.length } });
    };

    const onCreateClick = () => history.push(toNew(clientsRoute));
    const onClientClick = toClientRoute;

    const filter = useMemo(
        () => <ClientFilter values={filterValues} onChanges={onChanges} onCreateClick={onCreateClick} />,
        [filterValues, onChanges]
    );

    return (
        <PageLayout title="Клиенты" filter={filter}>
            <ObservedLayout loading={loading} loadMore={loadMore} keyValue={clients.length}>
                <Column width="100%" marginBottom="20px">
                    <ClientsTable onClientClick={onClientClick} data={clients} />
                </Column>
            </ObservedLayout>
            <Outlet />
        </PageLayout>
    );
};
