import styled from 'styled-components';

export const InputWrapper = styled.div``;

export const FormInputs = styled.div`
    padding: 5px 10px 0 0;
    height: 500px;
    overflow: auto;
    margin-bottom: 20px;
    ${InputWrapper} {
        margin-bottom: 20px;

        :last-child {
            margin-bottom: 0;
        }
    }
`;
