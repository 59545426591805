import { ButtonsModal as OriginButtonsModal } from '@C/components/common/modals/ButtonsModal';
import { useStore } from 'effector-react';
import { modalStores } from '@/stores/modals';
import { useOnOkModal } from '@/hooks/onOkModal';

export const ButtonsModal = () => {
    const { content, onOk, onError, ...buttonsModalProps } = useStore(modalStores.buttonsModal);

    const { isLoading, onOkFn } = useOnOkModal({ onOk, onError });

    return (
        <OriginButtonsModal onOk={onOkFn} loading={isLoading} {...buttonsModalProps}>
            {content}
        </OriginButtonsModal>
    );
};
