import { CalendarTypeOption } from '@/components/filters/SchedulerFilter/types';
import moment from 'moment/moment';
import { getDatesRangeInIsoWeekByDate } from '@C/utils/dateTimeUtils';

export const calendarTypesOption: CalendarTypeOption[] = [
    { label: 'День', value: 'day' },
    { label: 'Неделя', value: 'week-detailed' }
];

export const defaultPeriodByCalendarType = {
    day: [moment(new Date()).subtract(1, 'day').startOf('day'), moment(new Date()).endOf('day')],
    week: getDatesRangeInIsoWeekByDate(moment(new Date()))
};
