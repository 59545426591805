import { getUserData } from '@/utils/auth';
import { Navigate, useLocation } from 'react-router';
import { ComponentI } from '@/router/Routes/types';
import { baseRoute, defaultRoute, loginRoute, to } from '@/router/routerNames';

type PrivateRouteProps = ComponentI;

export const PrivateRoute = ({ Component }: PrivateRouteProps) => {
    const userData = getUserData();
    const { pathname } = useLocation();

    if (userData) {
        return pathname === baseRoute ? <Navigate to={defaultRoute} replace /> : <Component />;
    }

    return <Navigate to={to(loginRoute)} replace />;
};
