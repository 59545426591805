import { getUserData } from '@/utils/auth';
import { Navigate } from 'react-router';
import { ComponentI } from '@/router/Routes/types';
import { defaultRoute } from '@/router/routerNames';

type PublicRouteProps = ComponentI;

export const PublicRoute = ({ Component }: PublicRouteProps) => {
    const userData = getUserData();

    if (userData) {
        return <Navigate to={defaultRoute} replace />;
    }

    return <Component />;
};
