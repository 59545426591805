import {
    invalidEmailMessage,
    invalidPhoneMessage,
    requiredFieldMessage,
    requiredSetMessage
} from '@/constants/validation/messages';
import * as Yup from 'yup';
import { ru } from '@/constants/validation/yupRuLocale';

Yup.setLocale(ru);

export const yupString = Yup.string();
export const yupBoolean = Yup.boolean();
export const yupBooleanNullable = yupBoolean.nullable();
export const yupStringNullable = yupString.nullable();
export const yupNumber = Yup.number()
    .transform(value => (isNaN(value) ? undefined : value))
    .nullable();

export const yupNumberPositive = yupNumber.min(0);

export const yupNumberStrict = yupNumber.required(requiredFieldMessage);
export const yupBooleanStrict = yupBoolean.required(requiredFieldMessage);
export const yupNumberStrictPositive = yupNumberStrict.min(0);
export const yupDefault = yupString.required(requiredFieldMessage);
export const yupDefaultNullable = yupString.required(requiredFieldMessage).nullable();
export const yupDefaultArray = Yup.array().of(yupDefault).required(requiredSetMessage);

export const yupReactSelect = Yup.object().nullable();
export const yupReactSelectStrict = yupReactSelect.required(requiredFieldMessage);

export const yupEmail = yupString.email(invalidEmailMessage);
export const yupEmailStrict = yupEmail.required(requiredFieldMessage);

const phoneRegExp = /(^\+7)(\d{10})$/;

export const yupPhone = yupString.matches(phoneRegExp, invalidPhoneMessage);
export const yupPhoneStrict = yupPhone.required(requiredFieldMessage);
