import { lightGrey, middleGray, primaryBeige, primaryColor } from '@C/constants/styles/colors';
import styled from 'styled-components';
import { Column, FlexGrow, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { Information } from '@C/components/common/blocks/Information/styles';

export const TitleBlock = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    padding: 10px 15px;
    color: ${primaryColor};
`;

export const ContentColumn = styled(FlexGrow)`
    flex: 1 0 0;
`;

export const ProductWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0 0 20px 0;

    ${ContentColumn} {
        margin: 0 8px;

        :first-child {
            margin-left: 0;
        }

        :last-child {
            margin-right: 0;
        }
    }
`;

export const InformationWrapper = styled(Column)`
    width: 100%;

    ${Information} {
        margin-bottom: 4px;
    }
`;

export const IconWrapper = styled.div`
    z-index: 1;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    img {
        width: 20px !important;
        height: 30px !important;
    }
`;

export const StageButtonsWrapper = styled.div`
    display: flex;
    align-items: center;

    ${Row} {
        margin: 0 4px;

        :first-child {
            margin-left: 0;
        }

        :last-child {
            margin-right: 0;
        }
    }

    button {
        &:disabled {
            background-color: transparent;

            &:hover {
                svg,
                img {
                    path {
                        stroke: ${middleGray};
                    }
                }
            }
        }
    }
`;

export const PlusIconWrapper = styled.div`
    svg {
        width: 12px !important;
        height: 12px !important;

        rect {
            fill: ${primaryColor};
        }
    }
`;

export const DoneIconWrapper = styled.div`
    svg {
        rect {
            fill: ${primaryBeige};
        }
    }
`;

export const StatusInformationWrapper = styled.div`
    padding: 15px;
    width: 100%;
    background-color: ${lightGrey};

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    div {
        width: 100%;
        margin-bottom: 20px;

        :last-child {
            margin-bottom: 0;
        }

        h3 {
            font-weight: 700;
            margin: 0 0 4px 0;
        }
    }
`;
