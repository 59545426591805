import { ClientInterface, ShowedClientInfoType } from '@/components/contents/Client/ClientContent/types';
import { OnUpdateInterface } from '@C/types/data';
import { Button } from '@C/components/common/buttons/Button';
import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { ClientForm } from '@/components/forms/ClientForm';
import { modalEvents } from '@/stores/modals';
import {
    ClientWrapper,
    ContentColumn,
    InformationWrapper,
    TitleBlock
} from '@/components/contents/Client/ClientContent/styles';
import { showedClientInfoLabels } from '@/components/contents/Client/ClientContent/constants';
import { InformationItem } from '@C/components/common/blocks/Information';
import { flatMap } from 'lodash';
import { history } from '@/browserHistory';
import { ordersRoute, to } from '@/router/routerNames';

const { openModal, closeModal } = modalEvents;

interface ClientContentProps extends ClientInterface, OnUpdateInterface {}

export const ClientContent = ({ onUpdate, client }: ClientContentProps) => {
    const onChangeClick = () =>
        openModal({
            isOpen: true,
            title: 'Изменить данные клиента',
            onClose: closeModal,
            minWidth: '550px',
            content: <ClientForm onUpdate={onUpdate} type="edit" onClose={closeModal} client={client} />
        });

    const ordersClient = flatMap(
        client?.orders.map(({ products, id }) => products.map(({ name, id: productId }) => ({ id, name, productId })))
    );

    return (
        <Column width="100%">
            <Row width="100%" marginBottom="60px" justifyEnd>
                <Button onClick={onChangeClick}>Изменить</Button>
            </Row>
            <ClientWrapper>
                <ContentColumn>
                    <Row>
                        <TitleBlock>Личные данные</TitleBlock>
                    </Row>
                    <InformationWrapper>
                        {(Object.keys(showedClientInfoLabels) as ShowedClientInfoType[]).map(key => (
                            <InformationItem
                                key={key}
                                label={showedClientInfoLabels[key]}
                                // @ts-ignore
                                value={client?.[key]?.type || client?.[key] || ''}
                            />
                        ))}
                    </InformationWrapper>
                </ContentColumn>
                <ContentColumn>
                    <Row>
                        <TitleBlock>История заказов</TitleBlock>
                    </Row>
                    <InformationWrapper>
                        {ordersClient.map(({ id, name, productId }) => (
                            <InformationItem
                                onClick={() => history.push(to(ordersRoute) + to(String(id)))}
                                key={productId}
                                label={String(id)}
                                value={name}
                            />
                        ))}
                    </InformationWrapper>
                </ContentColumn>
            </ClientWrapper>
        </Column>
    );
};
