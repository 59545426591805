import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { useDeliveriesLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { ReactSelectOption } from '@C/types/data';

type DeliverySelectorProps = Omit<SelectorProps, 'options'>;

export const DeliverySelector = (props: DeliverySelectorProps) => {
    const [loadDeliveries, { data }] = useDeliveriesLazyQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });

    const options: ReactSelectOption[] = (data?.deliveries || []).map(({ id, name }) => ({
        label: name,
        value: id.toString()
    }));

    useEffect(() => {
        loadDeliveries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Selector options={options} {...props} />;
};
