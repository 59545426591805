import * as yup from 'yup';
import { yupDefault } from '@/constants/validation/yupFields';
import { MaterialMeta } from '@/components/forms/MaterialForm/types';

export const materialMeta: MaterialMeta = {
    name: {
        label: 'Наименование материала',
        placeholder: 'Наименование материала'
    },
    density: {
        label: 'Плотность материала',
        placeholder: 'Плотность материала'
    },
    format: {
        label: 'Формат материала',
        placeholder: 'Формат материала'
    }
};

export const yupSchema = yup.object({
    name: yupDefault,
    density: yupDefault,
    format: yupDefault
});
