import { ReactSelectOption } from '@C/types/data';
import { ProductType } from '@/components/contents/Order/Product/types';

export const varnishOptions: ReactSelectOption[] = [
    { value: 'true', label: 'Есть' },
    { value: 'false', label: 'Нет' },
    { value: 'null', label: 'н/у' }
];

export const getVarnishOptionsByVarnishValue = (varnishValue: ProductType['varnish']) => {
    const optionsValue = varnishValue === false ? 'false' : varnishValue === true ? 'true' : 'null';

    return varnishOptions.find(({ value }) => value === optionsValue);
};
