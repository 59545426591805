import { combine, createEvent, createStore } from 'effector';
import { OrdersForScheduleQueryVariables } from '@/graphql/api.types';
import { ReactSelectOption } from '@C/types/data';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';

export interface OrdersOnSchedulerFilterValues {
    status?: ReactSelectOption[] | null;
}

export interface OnChangeFilterValues {
    onStatusChange: OnSelectChange<true>;
}

const initialValues: OrdersOnSchedulerFilterValues = {
    status: undefined
};

const initialQueryParams = {};

export const setOrdersOnSchedulerFilterValue = createEvent<OrdersOnSchedulerFilterValues>();
export const clearOrdersOnSchedulerFilter = createEvent();
export const setOrdersOnSchedulerQueryParams = createEvent<OrdersForScheduleQueryVariables>();

const ordersOnSchedulerValuesStore = createStore<OrdersOnSchedulerFilterValues>(initialValues)
    .on(setOrdersOnSchedulerFilterValue, (state, newState) => ({ ...state, ...newState }))
    .on(clearOrdersOnSchedulerFilter, () => ({ ...initialValues }));

const ordersOnSchedulerQueryParamsStore = createStore<OrdersForScheduleQueryVariables>(initialQueryParams).on(
    setOrdersOnSchedulerQueryParams,
    (_, newQueryParams) => newQueryParams
);

ordersOnSchedulerValuesStore.watch(({ status }) => {
    const statusValue = status || [],
        newQueryParams = {
            ...initialQueryParams,
            status: statusValue?.length > 0 ? statusValue?.map(({ label }) => label) : undefined
        };

    setOrdersOnSchedulerQueryParams(newQueryParams);
});

const onChangeValuesStore = createStore<OnChangeFilterValues>({
    onStatusChange: status => setOrdersOnSchedulerFilterValue({ status: status as ReactSelectOption[] })
});

export const ordersOnSchedulerFilterStore = combine(
    ordersOnSchedulerValuesStore,
    ordersOnSchedulerQueryParamsStore,
    onChangeValuesStore
);
