import { useState } from 'react';
import { OkAsyncProps } from '@/stores/modals/buttonsModal';

export const useOnOkModal = ({ onOk, onError }: OkAsyncProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const onOkFn = async () => {
        try {
            if (onOk) {
                setIsLoading(true);
                await onOk();
            }
        } catch (e) {
            onError && onError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return { onOkFn, isLoading };
};
