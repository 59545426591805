import { ButtonsModalProps } from '@C/components/common/modals/ButtonsModal';
import { NoopFn } from '@/constants/common';
import { NoopAnyType, NoopType, PromiseFunctionType } from '@C/types/data';
import { modalFabric } from './modal';

export interface OkAsyncProps {
    onOk?: NoopType | PromiseFunctionType<void>;
    onError?: NoopAnyType;
}

interface ButtonsModalStore extends Omit<ButtonsModalProps, 'loading' | 'onOk'>, OkAsyncProps {
    content: JSX.Element;
}

const initialStore: ButtonsModalStore = {
    isOpen: false,
    onClose: NoopFn,
    onOk: NoopFn,
    onError: NoopFn,
    title: '',
    cancelText: '',
    okText: '',
    titleText: '',
    okDisabled: false,
    content: <></>
};

export const {
    modal: buttonsModal,
    openModal: openButtonsModal,
    setModal: setButtonsModal,
    closeModal: closeButtonsModal
} = modalFabric<ButtonsModalStore>(initialStore);
