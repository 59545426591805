import { RouteObject } from 'react-router/lib/router';
import { Navigate } from 'react-router';
import { Login } from '@/pages/Login';
import { PublicRoute } from '@/router/Routes/PublicRoute';
import { PrivateRoute } from '@/router/Routes/PrivateRoute';
import { Orders } from '@/pages/Orders';
import { Clients } from '@/pages/Clients';
import { Scheduler } from '@/pages/Scheduler';
import { Order } from '@/pages/Orders/Order';
import { NewOrder } from '@/pages/Orders/NewOrder';
import { Client } from '@/pages/Clients/Client';
import { NewClient } from '@/pages/Clients/NewClient';
import { Main } from '@/pages/Main';
import {
    defaultRoute,
    anyRoute,
    schedulerRoute,
    ordersRoute,
    newRoute,
    loginRoute,
    clientsRoute,
    baseRoute,
    toRoute
} from '@/router/routerNames';

export const router: RouteObject[] = [
    {
        path: baseRoute,
        element: <PrivateRoute Component={Main} />,
        children: [
            {
                path: ordersRoute,
                element: <PrivateRoute Component={Orders} />,
                children: [{ element: <PrivateRoute Component={NewOrder} />, path: newRoute, index: true }]
            },
            { element: <PrivateRoute Component={Order} />, path: `${toRoute(ordersRoute)}:orderId` },
            {
                path: clientsRoute,
                element: <PrivateRoute Component={Clients} />,
                children: [{ element: <PrivateRoute Component={NewClient} />, path: newRoute, index: true }]
            },
            { element: <PrivateRoute Component={Client} />, path: `${toRoute(clientsRoute)}:clientId` },
            {
                path: schedulerRoute,
                element: <PrivateRoute Component={Scheduler} />
            }
        ]
    },
    {
        path: loginRoute,
        element: <PublicRoute Component={Login} />
    },
    {
        path: anyRoute,
        element: <Navigate to={defaultRoute} replace />
    }
];
