import { Calendar } from '@C/components/common/ui/Calendar';
import { Moment } from 'moment';
import { useMemo } from 'react';
import { OnChangePeriod, PeriodDateType } from '@C/components/common/inputs/Datepicker/types';
import { Loading } from '@C/types/data';
import { OrderPlanQuery } from '@/graphql/api.types';
import { CalendarDataItem, CalendarTypeInterface } from '@C/components/common/ui/Calendar/types';
import moment from 'moment/moment';
import { calendarPalette } from '@C/constants/styles/colors';
import { colorByStatusMapper, iconByStatusMapper } from '@/components/contents/Scheduler/SchedulerTimeline/constants';

interface SchedulerTimelineProps extends Loading, CalendarTypeInterface {
    onPeriodChange: OnChangePeriod<Moment>;
    value?: PeriodDateType<Moment>;
    orderPlan?: OrderPlanQuery['getOrderPlan'];
    activeScheduleId?: string;
    onToggleActiveSchedule?: (id: string) => void;
}

export const SchedulerTimeline = ({
    loading,
    onPeriodChange,
    value,
    orderPlan,
    activeScheduleId,
    onToggleActiveSchedule,
    calendarType
}: SchedulerTimelineProps) => {
    const orderPlanData: CalendarDataItem<Moment, object>[] = useMemo(
        () =>
            (orderPlan || [])
                .filter(({ machines }) => machines.length > 0)
                .map(({ name, id, machines }) => ({
                    name,
                    id: String(id),
                    items: (machines || []).map(({ name, id, machineSchedules }) => ({
                        name,
                        id: String(id),
                        events: (machineSchedules || []).map(schedules => {
                            const isLinked = schedules?.length > 1,
                                { id, startAt, endAt, product, productStatus } = schedules?.[0] || {},
                                status = productStatus ? productStatus[productStatus.length - 1]?.status?.id : 0,
                                PostPositionIcon = iconByStatusMapper[status],
                                color =
                                    colorByStatusMapper[status] || calendarPalette[product.id % calendarPalette.length];

                            return {
                                id: String(id),
                                title: isLinked
                                    ? schedules.map(({ product }) => product.order.id).join(' | ')
                                    : `${product.order.id}`,
                                raw: {},
                                tooltipData: schedules.map(({ product }) => ({
                                    firstId: String(product.order.id),
                                    secondId: String(product.id),
                                    name: product.name
                                })),
                                PostPositionIcon,
                                color,
                                startDate: moment(startAt),
                                endDate: moment(endAt),
                                isLinked
                            };
                        })
                    }))
                })),
        [orderPlan]
    );

    return (
        <Calendar<Moment, object>
            periodValue={value}
            onPeriodChange={onPeriodChange}
            activeId={activeScheduleId}
            onEventClick={onToggleActiveSchedule}
            data={orderPlanData}
            defaultExpandAll
            loading={loading}
            isOuterDatePicker
            calendarType={calendarType}
        />
    );
};
