import { Tabs } from '@C/components/common/ui/tabs/Tabs';
import { OnChangeTabType } from '@C/components/common/ui/tabs/Tabs/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AddButtonWrapper, OrderContentWrapper } from '@/components/contents/Order/OrderContent/styles';
import { AbsoluteWrapper } from '@C/components/wrappers/grid/AbsoluteWrapper';
import { ButtonSelector } from '@C/components/common/inputs/ButtonSelect';
import { HamburgerIcon, PlusIcon } from '@C/assets/icons';
import {
    buttonsTop,
    defaultOrderFormModalParams,
    orderOptions
} from '@/components/contents/Order/OrderContent/constants';
import { Button } from '@C/components/common/buttons/Button';
import { Product } from '@/components/contents/Order/Product';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';
import {
    OnCancelOrderInterface,
    OnDeleteProductInterface,
    OnExportProductInterface,
    OnOrderActionsInterface,
    OrderInterface,
    OrderOptionActions,
    OrderOptionsTypes,
    ProductTab
} from '@/components/contents/Order/OrderContent/types';
import { modalEvents } from '@/stores/modals';
import { OrderForm } from '@/components/forms/OrderForm';
import { OnUpdateInterface } from '@C/types/data';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';

const { openModal, closeModal, openConfirmationModal, closeConfirmationModal } = modalEvents;

interface OrderContentProps
    extends OrderInterface,
        OnUpdateInterface,
        OnOrderActionsInterface,
        OnDeleteProductInterface,
        OnCancelOrderInterface,
        OnExportProductInterface {}

export const OrderContent = ({
    order,
    onUpdate,
    onOrderActions,
    onDeleteProduct,
    onCancelOrder,
    onExportProduct
}: OrderContentProps) => {
    const isImmutableOrder = order?.status.id === 4 || order?.status.id === 3;

    const tabs: ProductTab[] = useMemo(
        () =>
            (order?.products || []).map(product => {
                const { id, name } = product;
                return {
                    id: String(id) || '',
                    name: `№${id} ${name}`,
                    content: (
                        <Product
                            isImmutableOrder={isImmutableOrder}
                            onOrderActions={onOrderActions}
                            onUpdate={onUpdate}
                            order={order}
                            product={product}
                        />
                    ),
                    product
                };
            }),
        [isImmutableOrder, onOrderActions, onUpdate, order]
    );

    const [activeTab, setActiveTab] = useState<ProductTab | undefined>(tabs?.[0]);

    const onDeleteProductClick = useCallback(async () => {
        try {
            await onDeleteProduct(activeTab?.product?.id || 0);

            notify.success(
                <NotifyContent
                    title="Изделие успешно удалено"
                    text={
                        <>
                            Изделие <b>{activeTab?.product?.name}</b> успешно удалено
                        </>
                    }
                />
            );
            onUpdate && onUpdate();
        } catch (e) {
            const message = (e as { message: string })?.message;
            notify.error(<NotifyContent title={message || 'Что-то пошло не так'} />);
        } finally {
            closeConfirmationModal();
        }
    }, [activeTab, onDeleteProduct, onUpdate]);

    const orderOptionActions: OrderOptionActions = useMemo(
        () => ({
            copy: () => {
                openModal({
                    title: 'Дублировать заказ',
                    content: <OrderForm type="new" order={order} product={activeTab?.product} onClose={closeModal} />,
                    ...defaultOrderFormModalParams
                });
            },
            changeOrder: () => {
                openModal({
                    title: 'Изменить заказ',
                    content: (
                        <OrderForm
                            onUpdate={onUpdate}
                            type="editOrder"
                            order={order}
                            product={activeTab?.product}
                            onClose={closeModal}
                        />
                    ),
                    ...defaultOrderFormModalParams
                });
            },
            changeProduct: () => {
                openModal({
                    title: 'Изменить изделие',
                    content: (
                        <OrderForm
                            onUpdate={onUpdate}
                            type="editProduct"
                            order={order}
                            product={activeTab?.product}
                            onClose={closeModal}
                        />
                    ),
                    ...defaultOrderFormModalParams
                });
            },
            delete: () => {
                openConfirmationModal({
                    minWidth: '600px',
                    minHeight: '150px',
                    titleText: 'Удалить изделие',
                    confirmationText: (
                        <span>
                            Вы уверены что хотите удалить изделие под названием <b>«{activeTab?.name || ''}»</b>?
                        </span>
                    ),
                    okText: 'удалить',
                    cancelText: 'Отмена',
                    onOk: onDeleteProductClick
                });
            },
            cancelOrder: () => {
                openConfirmationModal({
                    minWidth: '600px',
                    minHeight: '150px',
                    titleText: 'Отменить заказ',
                    confirmationText: (
                        <span>
                            Внимание, при отмене заказа будут удалены все изделия и их связи, вы действительно хотите
                            отменить заказ <b>{order?.id}</b>?
                        </span>
                    ),
                    okText: 'Отменить',
                    cancelText: 'Отмена',
                    onOk: onCancelOrder
                });
            },
            exportProduct: () => onExportProduct(activeTab?.product?.id || 0)
        }),
        [activeTab?.name, activeTab?.product, onCancelOrder, onDeleteProductClick, onUpdate, order, onExportProduct]
    );

    const onOrderOptionChange: OnSelectChange = newValue =>
        newValue && orderOptionActions[newValue.value as OrderOptionsTypes]();

    const onAddProductClick = () =>
        openModal({
            title: 'Добавить изделие',
            content: (
                <OrderForm
                    type="newProduct"
                    order={order}
                    onUpdate={onUpdate}
                    product={activeTab?.product}
                    onClose={closeModal}
                />
            ),
            ...defaultOrderFormModalParams
        });

    useEffect(() => setActiveTab(tabs?.[0]), [tabs]);

    return (
        <OrderContentWrapper>
            <Tabs tabs={tabs} onTabChange={setActiveTab as OnChangeTabType} activeTab={activeTab} />
            <AbsoluteWrapper top={buttonsTop}>
                <ButtonSelector
                    options={orderOptions(tabs.length === 1, isImmutableOrder)}
                    onChange={onOrderOptionChange}
                    inputTheme="secondary"
                    buttonSelectIcon={
                        <Row zIndex="1">
                            <HamburgerIcon />
                        </Row>
                    }
                    withAutoClear
                />
            </AbsoluteWrapper>
            <AbsoluteWrapper top={buttonsTop} right="0">
                <Button
                    onClick={onAddProductClick}
                    colorTheme="secondary"
                    disabled={isImmutableOrder}
                    icon={
                        <AddButtonWrapper>
                            <PlusIcon />
                        </AddButtonWrapper>
                    }
                />
            </AbsoluteWrapper>
        </OrderContentWrapper>
    );
};
