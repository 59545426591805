import styled from 'styled-components';
import { softGray2, black, lightGrey } from '@C/constants/styles/colors';

export const AddNewMaterialSLot = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;

    min-height: 36px;
    border-bottom: 1px solid ${softGray2};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${black};
    padding: 10px 15px;
    transition: background-color 0.1s ease;
    :hover {
        background-color: ${lightGrey};
    }
`;

export const IconButtonWrapper = styled.div`
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;

    :hover {
        svg {
            opacity: 0.7;
        }
    }

    transition: opacity ease-in-out 100ms;
`;
