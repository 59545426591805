import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { useSizesLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { ReactSelectOption } from '@C/types/data';

type SizesSelectorProps = Omit<SelectorProps, 'options'>;

export const SizesSelector = (props: SizesSelectorProps) => {
    const [loadSizes, { data }] = useSizesLazyQuery({
        fetchPolicy: 'network-only'
    });

    const options: ReactSelectOption[] = (data?.sizes || []).map(({ id, name }) => ({
        label: name,
        value: id.toString()
    }));

    useEffect(() => {
        loadSizes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Selector options={options} {...props} />;
};
