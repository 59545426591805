import {
    ContentColumn,
    DoneIconWrapper,
    IconWrapper,
    InformationWrapper,
    PlusIconWrapper,
    ProductWrapper,
    StageButtonsWrapper,
    StatusInformationWrapper,
    TitleBlock
} from '@/components/contents/Order/Product/styles';
import { FlexGrow, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { InformationItem } from '@C/components/common/blocks/Information';
import { DeleteIcon, LinkIcon, MoreIcon, NextArrow4Icon, PlusIcon, StatusDoneIcon } from '@C/assets/icons';
import { Button } from '@C/components/common/buttons/Button';
import { ButtonSelector } from '@C/components/common/inputs/ButtonSelect';
import {
    convertProductToViewList,
    noSet,
    showedOrderInfoLabels,
    showedProductInfoLabels,
    stageOptions
} from '@/components/contents/Order/Product/constants';
import { modalEvents } from '@/stores/modals';
import { StageForm } from '@/components/forms/StageForm';
import { StatusForm } from '@/components/forms/StatusForm';
import { OnOrderActionsInterface, OrderInterface } from '@/components/contents/Order/OrderContent/types';
import {
    IsImmutableOrder,
    ProductInterface,
    ShowedProductInfoType,
    StageOptionsActions,
    StageOptionsProps,
    StageOptionsTypes
} from '@/components/contents/Order/Product/types';
import moment from 'moment';
import { defaultDateTimeFormat } from '@C/constants/dateTime';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';
import { ProductScheduleType } from '@/components/forms/StageForm/types';
import { convertDurationInMinToMoment } from '@C/utils/dateTimeUtils';
import { OnUpdateInterface } from '@C/types/data';
import { StageCommunicationCombobox } from '@/components/inputs/StageCommunicationCombobox';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';
import { toClientRoute } from '@/router/routerFn';

const { openModal, closeModal, openConfirmationModal, closeConfirmationModal } = modalEvents;

interface StagesProps extends ProductInterface, OnUpdateInterface, OnOrderActionsInterface, IsImmutableOrder {}

const Stages = ({ product, onUpdate, onOrderActions, isImmutableOrder }: StagesProps) => {
    const { onDownPriority, onUpPriority, onDeleteSchedule, onDeleteRelationSchedule, onCreateRelationSchedule } =
        onOrderActions;

    const stages = useMemo(
        () =>
            (product?.productSchedules || [])
                .map(productSchedule => {
                    const { productStatus, stageTime, id, operation } = productSchedule;
                    return {
                        operation,
                        id,
                        duration: convertDurationInMinToMoment(stageTime).format('HH ч mm м'),
                        statuses: productStatus.map(({ status, id, createdAt, schedule, description }) => ({
                            status,
                            id,
                            createdAt,
                            schedule,
                            description
                        })),
                        productSchedule
                    };
                })
                .sort((a, b) => a?.productSchedule?.priority - b?.productSchedule?.priority),
        [product]
    );

    const [isNewRelationVisible, setIsNewRelationVisible] = useState(false);

    const [activeStage, setActiveStage] = useState(stages?.[0]);
    const isActiveNewStage = activeStage?.operation?.id === 1;
    const isAlreadyScheduledStage = !!activeStage?.productSchedule?.startAt;

    const activeProductSchedule = activeStage?.productSchedule;
    const activeProductStatuses = activeProductSchedule?.productStatus || [];
    const activeStatusInformation = activeProductStatuses[activeProductStatuses.length - 1];

    useEffect(() => {
        setIsNewRelationVisible(false);
    }, [activeStage]);

    const onNewStageClick = useCallback(
        () =>
            openModal({
                title: 'Новый этап',
                minWidth: '500px',
                content: <StageForm onUpdate={onUpdate} productId={product!.id} onClose={closeModal} />
            }),
        [onUpdate, product]
    );

    const onNewStatusClick = useCallback(
        () =>
            openModal({
                title: 'Новый статус заказа',
                minWidth: '700px',
                minHeight: '300px',
                content: <StatusForm productSchedule={activeProductSchedule} onUpdate={onUpdate} onClose={closeModal} />
            }),
        [activeProductSchedule, onUpdate]
    );
    const onDeleteScheduleClick = useCallback(
        async (productSchedule: ProductScheduleType) => {
            try {
                await onDeleteSchedule(productSchedule.id);

                notify.success(
                    <NotifyContent
                        title="Этап успешно удален"
                        text={
                            <>
                                Этап <b>{productSchedule.operation.name}</b> успешно удален
                            </>
                        }
                    />
                );
                onUpdate && onUpdate();
            } catch (e) {
                notify.error(<NotifyContent title="Что-то пошло не так" />);
            } finally {
                closeConfirmationModal();
            }
        },
        [onDeleteSchedule, onUpdate]
    );

    const stageOptionActions: (productSchedule: ProductScheduleType) => StageOptionsActions = useCallback(
        productSchedule => {
            const { id } = productSchedule;
            return {
                up: async () => {
                    await onUpPriority(id);
                    onUpdate && onUpdate();
                },
                down: async () => {
                    await onDownPriority(id);
                    onUpdate && onUpdate();
                },
                detail: () => {
                    openModal({
                        title: 'Изменить этап',
                        minWidth: '500px',
                        content: (
                            <StageForm
                                type="edit"
                                productSchedule={productSchedule}
                                productId={product!.id}
                                onClose={closeModal}
                                onUpdate={onUpdate}
                            />
                        )
                    });
                },
                delete: () => {
                    openConfirmationModal({
                        minWidth: '600px',
                        minHeight: '150px',
                        titleText: 'Удалить этап',
                        confirmationText: (
                            <span>
                                Вы уверены что хотите удалить этап <b>«{productSchedule.operation.name || ''}»</b>?
                            </span>
                        ),
                        okText: 'удалить',
                        onOk: async () => await onDeleteScheduleClick(productSchedule),
                        cancelText: 'Отмена'
                    });
                }
            };
        },
        [onDeleteScheduleClick, onDownPriority, onUpPriority, onUpdate, product]
    );

    const onRelationChange: OnSelectChange = useCallback(
        async value => {
            try {
                await onCreateRelationSchedule({
                    currentScheduleId: Number(activeProductSchedule?.id),
                    relatedScheduleId: Number(value?.value)
                });
                notify.success(
                    <NotifyContent
                        title="Связь успешно создана"
                        text={
                            <>
                                Связь между этапом <b>{activeStage?.operation.name}</b> успешно создана
                            </>
                        }
                    />
                );
                onUpdate && onUpdate();
            } catch {
                notify.error(<NotifyContent title="Что-то пошло не так" />);
            }
        },
        [activeProductSchedule?.id, activeStage?.operation.name, onCreateRelationSchedule, onUpdate]
    );

    const onDeleteRelation = useCallback(
        (id: number) => async () => {
            try {
                await onDeleteRelationSchedule(id);
                notify.info(
                    <NotifyContent
                        title="Связь успешно удалена"
                        text={
                            <>
                                Связь между этапом <b>{activeStage?.operation.name}</b> успешно удалена
                            </>
                        }
                    />
                );
                onUpdate && onUpdate();
            } catch {
                notify.error(<NotifyContent title="Что-то пошло не так" />);
            }
        },
        [activeStage, onDeleteRelationSchedule, onUpdate]
    );

    useEffect(() => setActiveStage(stages?.[0]), [stages]);

    return (
        <>
            <ContentColumn>
                <Row>
                    <TitleBlock>Этапы</TitleBlock>
                </Row>
                <InformationWrapper>
                    {stages?.map(stage => {
                        const { id, duration, productSchedule, operation } = stage,
                            { isComplit, priority } = productSchedule;
                        return (
                            <InformationItem
                                key={id}
                                label={operation.name}
                                active={id === activeStage?.id}
                                paddingTop="0"
                                paddingBottom="0"
                                value={
                                    isComplit ? (
                                        <DoneIconWrapper>
                                            <StatusDoneIcon />
                                        </DoneIconWrapper>
                                    ) : (
                                        <StageButtons
                                            stageOptionActions={stageOptionActions(productSchedule)}
                                            disabledStageButton={isImmutableOrder}
                                            duration={duration}
                                            isLink={!!productSchedule.relationSchedules?.length}
                                            disabled={{
                                                up: priority === 0,
                                                down: priority === stages.length - 1,
                                                delete:
                                                    stages.length === 1 ||
                                                    product?.productionDetail?.operationStatus?.schedule?.id === id
                                            }}
                                        />
                                    )
                                }
                                onClick={() => setActiveStage(stage)}
                            />
                        );
                    })}
                </InformationWrapper>
                <Button
                    icon={
                        <PlusIconWrapper>
                            <PlusIcon />
                        </PlusIconWrapper>
                    }
                    colorTheme="secondary"
                    disabled={isImmutableOrder}
                    onClick={onNewStageClick}
                >
                    Новый этап
                </Button>
            </ContentColumn>
            <ContentColumn>
                <Row>
                    <TitleBlock>История этапа</TitleBlock>
                </Row>
                <InformationWrapper>
                    {activeStage?.statuses.map(({ status, id, createdAt }) => (
                        <InformationItem
                            key={id}
                            icon={<NextArrow4Icon />}
                            label={status?.name}
                            value={moment(createdAt).format(defaultDateTimeFormat)}
                        />
                    ))}
                </InformationWrapper>
                <Row marginBottom="30px" width="100%" justifyEnd>
                    <Button onClick={onNewStatusClick} disabled={isImmutableOrder || isActiveNewStage}>
                        Изменить статус
                    </Button>
                </Row>
                <Row>
                    <TitleBlock>Инфо по статусу</TitleBlock>
                </Row>
                <StatusInformationWrapper>
                    <div>
                        <h3>Ответственный</h3>
                        <span>{activeStatusInformation?.schedule?.manager?.name}</span>
                    </div>
                    {activeStatusInformation?.schedule?.machine && (
                        <div>
                            <h3>Оборудование</h3>
                            <span>{activeStatusInformation?.schedule?.machine?.name}</span>
                        </div>
                    )}
                    {activeStatusInformation?.description && (
                        <div>
                            <h3>Комментарий</h3>
                            <span>{activeStatusInformation?.description}</span>
                        </div>
                    )}
                </StatusInformationWrapper>
            </ContentColumn>
            <ContentColumn>
                <Row>
                    <TitleBlock>Связи этапа</TitleBlock>
                </Row>
                {(activeProductSchedule.relationSchedules || []).map(({ id, product }) => {
                    const { name, orderId } = product;
                    return (
                        <Row key={id} width="100%" marginBottom="4px" alignCenter>
                            <FlexGrow>
                                <StageCommunicationCombobox
                                    value={{ label: `${orderId} ${name}`, value: String(id) }}
                                    disabled
                                    inputTheme="secondary"
                                />
                            </FlexGrow>
                            <Row marginLeft="5px">
                                <Button onClick={onDeleteRelation(id)} icon={<DeleteIcon />} colorTheme="secondary" />
                            </Row>
                        </Row>
                    );
                })}
                {isNewRelationVisible && (
                    <Row width="100%" marginBottom="4px" alignCenter>
                        <FlexGrow>
                            <StageCommunicationCombobox
                                operationId={activeProductSchedule?.operation?.id}
                                inputTheme="secondary"
                                onChange={onRelationChange}
                                currentProductId={product?.id}
                                placeholder="Выберите"
                            />
                        </FlexGrow>
                        <Row marginLeft="5px">
                            <Button
                                onClick={() => setIsNewRelationVisible(false)}
                                icon={<DeleteIcon />}
                                colorTheme="secondary"
                                disabled={isImmutableOrder}
                            />
                        </Row>
                    </Row>
                )}
                <Row>
                    <Button
                        onClick={() => setIsNewRelationVisible(true)}
                        disabled={
                            isNewRelationVisible || isImmutableOrder || isActiveNewStage || isAlreadyScheduledStage
                        }
                        icon={
                            <PlusIconWrapper>
                                <PlusIcon />
                            </PlusIconWrapper>
                        }
                        colorTheme="secondary"
                    >
                        Новая связь
                    </Button>
                </Row>
            </ContentColumn>
        </>
    );
};

interface StageButtonsProps extends StageOptionsProps {
    duration: string;
    isLink?: boolean;
    stageOptionActions: StageOptionsActions;
    disabledStageButton?: boolean;
}

const StageButtons = ({ duration, isLink, stageOptionActions, disabled, disabledStageButton }: StageButtonsProps) => {
    const onStageOptionsChange: OnSelectChange = newValue =>
        newValue && stageOptionActions[newValue.value as StageOptionsTypes]();

    return (
        <StageButtonsWrapper>
            <Row>
                <span>{duration}</span>
            </Row>
            {isLink && (
                <Row height="32px">
                    <IconWrapper>
                        <LinkIcon />
                    </IconWrapper>
                </Row>
            )}
            <Row alignCenter>
                <ButtonSelector
                    options={stageOptions({ disabled })}
                    disabled={disabledStageButton}
                    inputTheme="secondary"
                    height="100%"
                    withAutoClear
                    onChange={onStageOptionsChange}
                    buttonSelectIcon={
                        <IconWrapper>
                            <MoreIcon />
                        </IconWrapper>
                    }
                />
            </Row>
        </StageButtonsWrapper>
    );
};

interface ProductProps
    extends OrderInterface,
        ProductInterface,
        OnUpdateInterface,
        OnOrderActionsInterface,
        IsImmutableOrder {}

export const Product = ({ product, order, onUpdate, onOrderActions, isImmutableOrder }: ProductProps) => {
    const productViewList = convertProductToViewList(product);

    return (
        <ProductWrapper>
            <ContentColumn>
                <Row>
                    <TitleBlock>Информация о заказе</TitleBlock>
                </Row>
                <InformationWrapper>
                    <InformationItem label={showedOrderInfoLabels.name} value={order?.name} />
                    <InformationItem
                        label={showedOrderInfoLabels.client}
                        value={order?.client.name}
                        onClick={() => toClientRoute(order?.client.id || 0)}
                    />
                    <InformationItem label={showedOrderInfoLabels.address} value={order?.address || noSet} />
                    <InformationItem label={showedOrderInfoLabels.delivery} value={order?.delivery.name} />
                    <InformationItem label={showedOrderInfoLabels.description} value={order?.description || noSet} />
                    <InformationItem
                        label={showedOrderInfoLabels.deadlineAt}
                        value={moment(order?.deadlineAt).format(defaultDateTimeFormat)}
                    />
                    <InformationItem
                        label={showedOrderInfoLabels.machine}
                        value={product?.productionDetail?.productionMachines?.name}
                    />
                </InformationWrapper>
                <Row>
                    <TitleBlock>Информация об изделии</TitleBlock>
                </Row>
                {productViewList && (
                    <InformationWrapper>
                        {(Object.keys(showedProductInfoLabels) as ShowedProductInfoType[]).map(key => {
                            const value = productViewList[key];

                            if (!value) return;

                            const showedValue = typeof value === 'number' ? String(value) : value || noSet;

                            return (
                                <InformationItem key={key} label={showedProductInfoLabels[key]} value={showedValue} />
                            );
                        })}
                    </InformationWrapper>
                )}
            </ContentColumn>
            <Stages
                isImmutableOrder={isImmutableOrder}
                onOrderActions={onOrderActions}
                product={product}
                onUpdate={onUpdate}
            />
        </ProductWrapper>
    );
};
