import * as yup from 'yup';
import {
    yupDefaultNullable,
    yupReactSelectStrict,
    yupString,
    yupStringNullable
} from '@/constants/validation/yupFields';

export const stageMeta = {
    stage: {
        label: 'Этап',
        placeholder: 'Этап'
    },
    durationHour: {
        label: 'Часов'
    },
    durationMinute: {
        label: 'Минут'
    },
    startDate: {
        label: 'Дата начала'
    },
    startHour: {
        label: 'Час начала'
    },
    startMinute: {
        label: 'Минута начала'
    },
    endDate: {
        label: 'Дата окончания'
    },
    endHour: {
        label: 'Час окончания'
    },
    endMinute: {
        label: 'Минута окончания'
    },
    equipment: {
        label: 'Оборудование',
        placeholder: 'Оборудование'
    },
    comment: {
        label: 'Комментарий'
    }
};

export const yupSchema = yup.object({
    stage: yupReactSelectStrict,
    // equipment: yupReactSelect,
    duration: yupDefaultNullable,
    startDate: yupString,
    startTime: yupStringNullable,
    // endDate: yupString,
    // endTime: yupStringNullable,
    comment: yupString
});
