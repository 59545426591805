import styled from 'styled-components';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { RemoveMarginRight, Sizes } from '@C/types/styles';

interface InputWrapperProps extends RemoveMarginRight, Pick<Sizes, 'width'> {}

export const InputWrapper = styled.div<InputWrapperProps>`
    width: ${({ width }) => width || '200px'};
    ${({ removeMarginRight }) => !removeMarginRight && 'margin-right: 10px'};
`;

export const FilterWrapper = styled(Row)``;
