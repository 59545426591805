import { createGlobalStyle, css } from 'styled-components';
import { globalStyleMixin } from '@C/constants/styles/global';
import { breakpoint, containerSize } from '@C/constants/styles/breackpoints';
import { HeaderContent } from '@/components/grid/Headers/MainHeader/styles';
import { MainContent } from '@/components/layouts/MainLayout/styles';
import { selectorStyleMixin } from '@C/components/common/inputs/Select/styles';

export const mediaMixin = css`
    @media (max-width: ${`${breakpoint.xxxl}px`}) {
        ${HeaderContent} {
            max-width: ${containerSize.xxxl}px;
        }

        ${MainContent} {
            max-width: ${containerSize.xxxl}px;
        }
    }
    @media (max-width: ${`${breakpoint.xxl}px`}) {
        ${HeaderContent} {
            max-width: ${containerSize.xxl}px;
        }

        ${MainContent} {
            max-width: ${containerSize.xxl}px;
        }
    }

    @media (max-width: ${`${breakpoint.xl}px`}) {
        ${HeaderContent} {
            max-width: ${containerSize.xl}px;
        }
        ${MainContent} {
            max-width: ${containerSize.xl}px;
        }
    }

    @media (max-width: ${`${breakpoint.l}px`}) {
        ${HeaderContent} {
            max-width: ${containerSize.l}px;
        }
        ${MainContent} {
            max-width: ${containerSize.l}px;
        }
    }

    @media (max-width: ${`${breakpoint.m}px`}) {
        ${HeaderContent} {
            max-width: 100%;
        }
        ${MainContent} {
            max-width: 100%;
        }
    }

    @media (max-width: ${`${breakpoint.s}px`}) {
        ${HeaderContent} {
            max-width: 100%;
        }
        ${MainContent} {
            max-width: 100%;
        }
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${globalStyleMixin};
  
  #portal-root {
          ${selectorStyleMixin};
  }
  
  ${mediaMixin};
`;
