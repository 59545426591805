import { Table } from '@C/components/common/ui/tables/Table';
import { OrderBoardTableData } from '@/components/tables/OrderBoardTable/types';
import { columns } from '@/components/tables/OrderBoardTable/constants';
import { useMemo } from 'react';
import { onRowClickType } from '@C/components/common/ui/tables/Table/types';
import { Tooltip } from '@C/components/common/ui/Tooltip';

interface OrderBoardTableProps {
    data?: OrderBoardTableData[];
    onRowClick: onRowClickType<OrderBoardTableData>;
}

export const OrderBoardTable = ({ data = [], onRowClick }: OrderBoardTableProps) => {
    const tableData: OrderBoardTableData[] = useMemo(() => data, [data]);

    return (
        <>
            <Table<OrderBoardTableData> onRowClick={onRowClick} columns={columns} data={tableData} />
            <Tooltip />
        </>
    );
};
