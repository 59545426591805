import { combine, createEvent, createStore } from 'effector';
import { ClientsQueryVariables, SortOrder } from '@/graphql/api.types';
import { OnTextChange } from '@C/components/common/inputs/TextField/types';
import { defaultTake } from '@/constants/store';

export interface ClientsFilterValues {
    search?: string;
}

export interface OnChangeFilterValues {
    onSearchChange: OnTextChange;
}

const initialValues: ClientsFilterValues = {
    search: ''
};

const initialQueryParams: ClientsQueryVariables = {
    take: defaultTake,
    skip: 0,
    orderBy: {
        id: SortOrder.Asc
    }
};

export const setClientsFilterValue = createEvent<ClientsFilterValues>();
export const clearClientsFilter = createEvent();
export const setClientsQueryParams = createEvent<ClientsQueryVariables>();

const clientsFilterValuesStore = createStore<ClientsFilterValues>(initialValues)
    .on(setClientsFilterValue, (state, newState) => ({ ...state, ...newState }))
    .on(clearClientsFilter, () => ({ ...initialValues }));

const clientsQueryParamsStore = createStore<ClientsQueryVariables>(initialQueryParams).on(
    setClientsQueryParams,
    (_, newQueryParams) => newQueryParams
);

clientsFilterValuesStore.watch(({ search }) => {
    setClientsQueryParams({
        ...initialQueryParams,
        search
    });
});

const onChangeValuesStore = createStore<OnChangeFilterValues>({
    onSearchChange: ({ target: { value: search } }) => setClientsFilterValue({ search })
});

export const clientsFilterStore = combine(clientsFilterValuesStore, clientsQueryParamsStore, onChangeValuesStore);
