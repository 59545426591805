import { useEffect } from 'react';
import { modalEvents } from '@/stores/modals';
import { OrderForm } from '@/components/forms/OrderForm';
import { history } from '@/browserHistory';

const { openModal, closeModal } = modalEvents;

export const NewOrder = () => {
    const onClose = () => {
        history.back();
        closeModal();
    };

    useEffect(() => {
        openModal({
            isOpen: true,
            title: 'Новый заказ',
            onClose,
            minWidth: '700px',
            content: <OrderForm onClose={onClose} />
        });

        return () => {
            closeModal();
        };
    }, []);

    return <></>;
};
