import { ButtonsModal } from '@/components/modals/ButtonsModal';
import { Modal } from '@/components/modals/Modal';
import { useNonScrolledBackground } from '@C/hooks/useNonScrolledBackground';
import { modalStores } from '@/stores/modals';
import { useStore } from 'effector-react';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal';

export const AppModals = () => {
    const { isOpen: isOpenModal } = useStore(modalStores.modal);
    const { isOpen: isOpenButtonsModal } = useStore(modalStores.buttonsModal);

    useNonScrolledBackground(isOpenModal || isOpenButtonsModal);

    return (
        <>
            <ButtonsModal />
            <Modal />
            <ConfirmationModal />
        </>
    );
};
