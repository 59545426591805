import { Header, HeaderContent, LogoWrapper } from '@/components/grid/Headers/MainHeader/styles';
import { HeaderMenu } from '@C/components/common/ui/tabs//HeaderMenu';
import { headerHorizontalPadding, menuItems } from '@/components/grid/Headers/MainHeader/constants';
import { ItemKey } from '@/components/grid/Headers/MainHeader/types';
import { toAny } from '@/router/routerNames';
import logoImage from '@C/assets/images/logo.svg';
import { AbsoluteWrapper } from '@C/components/wrappers/grid/AbsoluteWrapper';
import { LogoutBlock, LogoutBlockProps } from '@C/components/common/blocks/LogoutBlock';

interface MainHeaderProps extends LogoutBlockProps {
    isActive: (path: string) => boolean;
    onItemClick: (path: string) => void;
}

export const MainHeader = ({ isActive, onItemClick, ...logoutProps }: MainHeaderProps) => {
    const activeItemKey = menuItems.find(({ path }) => isActive(toAny(path)))?.key;

    const onClick = (itemKey: ItemKey) => {
        const { path } = menuItems.find(({ key }) => itemKey === key)!;
        onItemClick(path);
    };

    return (
        <Header>
            <HeaderContent>
                <HeaderMenu<ItemKey> activeItemKey={activeItemKey} onItemClick={onClick} items={menuItems} />
                <LogoWrapper>
                    <img src={logoImage} alt="logo" />
                </LogoWrapper>
                <AbsoluteWrapper right={headerHorizontalPadding} bottom="23px">
                    <LogoutBlock {...logoutProps} />
                </AbsoluteWrapper>
            </HeaderContent>
        </Header>
    );
};
