import { TextField } from '@C/components/common/inputs/TextField';
import { DatePicker } from '@C/components/common/inputs/Datepicker';
import React, { useId, useState } from 'react';
import { InputWrapper } from '@/components/layouts/FilterLayout/styles';
import { Moment } from 'moment';
import { Button } from '@C/components/common/buttons/Button';
import { DeleteIcon, ExportIcon } from '@C/assets/icons';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { FilterLayout } from '@/components/layouts/FilterLayout';
import { CreateClick } from '@/components/layouts/FilterLayout/types';
import { OnChangeFilterValues, OrdersFilterValues } from '@/stores/filters/ordersFilter';
import { filterEvents } from '@/stores/filters';
import { StatusSelector } from '@/components/inputs/StatusSelector';
import { ClientsCombobox } from '@/components/inputs/ClientsCombobox';
import { FlexGrow, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { ExportIconWrapper } from '@/components/filters/OrderFilter/styles';
import { PromiseFunctionType } from '@C/types/data';

const { clearOrdersFilter } = filterEvents;

interface OrderFilterProps extends CreateClick {
    values: OrdersFilterValues;
    onChanges: OnChangeFilterValues;
    onLoad: PromiseFunctionType<void>;
}

export const OrderFilter = ({ onCreateClick, values, onChanges, onLoad }: OrderFilterProps) => {
    const [exportLoading, setExportLoading] = useState(false);
    const id = useId();

    const { status, search, period, clientId } = values;
    const { onPeriodChange, onSearchChange, onClientIdChange, onStatusChange } = onChanges;

    const onExportClick = async () => {
        setExportLoading(true);
        await onLoad();
        setExportLoading(false);
    };

    return (
        <FilterLayout onCreateClick={onCreateClick}>
            <FlexGrow justifyBetween flexDirection="row" alignCenter>
                <Row>
                    <Button
                        onClick={onExportClick}
                        disabled={exportLoading}
                        dataTip="Экспорт"
                        icon={
                            <ExportIconWrapper>
                                <ExportIcon />
                            </ExportIconWrapper>
                        }
                        colorTheme="secondary"
                    />
                </Row>
                <Row>
                    <InputWrapper>
                        <TextField
                            onChange={onSearchChange}
                            value={search}
                            placeholder="Поиск заказа"
                            inputTheme="secondary"
                            id={`${id}-search`}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <ClientsCombobox
                            value={clientId}
                            onChange={onClientIdChange}
                            placeholder="Клиент"
                            inputTheme="secondary"
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <DatePicker<Moment>
                            label=""
                            type="period"
                            placeholder="Все периоды"
                            returnedType="Moment"
                            name={`${id}-period`}
                            onChange={onPeriodChange}
                            value={period}
                            inputTheme="secondary"
                            id={`${id}-period`}
                        />
                    </InputWrapper>
                    <InputWrapper removeMarginRight>
                        <StatusSelector
                            onChange={onStatusChange}
                            isMulti
                            value={status}
                            placeholder="Все статусы"
                            inputTheme="secondary"
                            id={`${id}-status`}
                        />
                    </InputWrapper>
                    <MarginWrapper marginLeft="10px">
                        <Button onClick={clearOrdersFilter} icon={<DeleteIcon />} colorTheme="secondary" />
                    </MarginWrapper>
                </Row>
            </FlexGrow>
        </FilterLayout>
    );
};
