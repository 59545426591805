import { ShowedClientInfoLabels } from '@/components/contents/Client/ClientContent/types';

export const showedClientInfoLabels: ShowedClientInfoLabels = {
    name: 'ФИО',
    clientType: 'Тип клиента',
    inn: 'ИНН',
    contact: 'Контактное лицо',
    contact2: 'Контактное лицо',
    contact3: 'Контактное лицо',
    phone: 'Телефон',
    email: 'Электронная почта',
    address: 'Адрес доставки',
    documents: 'Требования к отгрузочным документам'
};
