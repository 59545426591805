import styled from 'styled-components';
import { Column } from '@C/components/wrappers/grid/FlexWrapper';

export const ObservedWrapper = styled(Column)`
    position: relative;
    height: 100px;
`;

export const Observe = styled.div`
    position: absolute;
    width: 100%;
    height: 2px;
`;
