import { useEffect, useId, useState } from 'react';
import { Form, FormInputs, InputWrapper, Subtitle } from '@/components/forms/OrderForm/styles';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextField } from '@C/components/common/inputs/TextField';
import { OrderInputs } from '@/components/forms/OrderForm/types';
import { orderMeta, yupSchema } from '@/components/forms/OrderForm/constants';
import { ClientsCombobox } from '@/components/inputs/ClientsCombobox';
import { NoopType, OnUpdateInterface } from '@C/types/data';
import { MachinesCombobox } from '@/components/inputs/MachinesCombobox';
import { SizesSelector } from '@/components/inputs/SizesSelector';
import { DatePicker } from '@C/components/common/inputs/Datepicker';
import moment, { Moment } from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateOrderMutation, useCreateProductMutation, useUpdateOrderMutation } from '@/graphql/api.types';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';
import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { portalRootSelector } from '@/constants/common';
import { MaterialTypeSelector } from '@/components/inputs/MaterialTypeSelector';
import { DeliverySelector } from '@/components/inputs/DeliverySelector';
import { OrderInterface } from '@/components/contents/Order/OrderContent/types';
import { ProductInterface } from '@/components/contents/Order/Product/types';
import { ProductTypeSelector } from '@/components/inputs/ProductTypeSelector';
import { usingValue } from '@/utils/forms';
import { requiredFieldMessage } from '@/constants/validation/messages';
import { VarnishSelector } from '@/components/inputs/VarnishSelector';

interface OrderFormProps extends OrderInterface, ProductInterface, OnUpdateInterface {
    onClose: NoopType;
    type?: 'new' | 'editOrder' | 'editProduct' | 'newProduct';
}

export const OrderForm = ({ onClose, order, product, type = 'new', onUpdate }: OrderFormProps) => {
    const isNew = type === 'new',
        isNewProduct = type === 'newProduct',
        isEditOrder = type === 'editOrder',
        isEditProduct = type === 'editProduct';

    const id = useId();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        watch,
        formState: { isValid, errors }
    } = useForm<OrderInputs>({ resolver: yupResolver(yupSchema), mode: 'onChange' });

    // @ts-ignore
    const isDeliverySelected = watch('delivery')?.value === '2';
    const addressValue = watch('address');
    const hasAddressError = isDeliverySelected && !addressValue?.length;

    const [createOrder] = useCreateOrderMutation();
    const [createProduct] = useCreateProductMutation();
    const [updateOrder] = useUpdateOrderMutation();

    const onSubmit: SubmitHandler<OrderInputs> = async ({
        clientId,
        machineId,
        size,
        materialId,
        delivery,
        deadlineAt,
        filmType,
        typeOfFoil,
        productType,
        address,
        description,
        orderName,
        varnish,
        ...data
    }) => {
        try {
            setLoading(true);

            const addressValue = isDeliverySelected ? address : '';

            if (isNew) {
                const input = {
                    clientId: Number(clientId.value),
                    machineId: Number(machineId?.value),
                    size: size.label,
                    ...(materialId?.value !== materialId?.label && { materialId: Number(materialId?.value) }),
                    ...(materialId?.value === materialId?.label && { materialName: materialId?.value || '' }),
                    deliveryId: Number(delivery.value),
                    deadlineAt: moment(deadlineAt).format('YYYY-MM-DD'),
                    filmTypeId: Number(filmType?.value),
                    typeOfFoilId: Number(typeOfFoil?.value),
                    productTypeId: Number(productType?.value),
                    address: addressValue,
                    description,
                    orderName,
                    varnish,
                    ...data
                };

                const returnedCreateOrder = await createOrder({
                    variables: { input }
                });

                notify.success(
                    <NotifyContent
                        title="Заказ успешно создан"
                        text={
                            <>
                                Заказ № <b>{returnedCreateOrder.data?.createOrder?.id || ''}</b> создан
                            </>
                        }
                    />
                );
            }

            if (isNewProduct) {
                const input = {
                    orderId: order?.id || 0,
                    machineId: machineId && Number(machineId.value),
                    size: size.label,
                    materialId: materialId && Number(materialId.value),
                    filmTypeId: Number(filmType?.value),
                    typeOfFoilId: Number(typeOfFoil?.value),
                    productTypeId: Number(productType?.value),
                    ...data
                };

                const returnedCreateProduct = await createProduct({
                    variables: { input }
                });

                notify.success(
                    <NotifyContent
                        title="Изделие успешно создано"
                        text={
                            <>
                                Изделие <b>{returnedCreateProduct.data?.createProduct?.name || ''}</b> создано
                            </>
                        }
                    />
                );
            }

            if (isEditOrder) {
                const descriptionValue = usingValue(description, {
                    description: {
                        set: description
                    }
                });

                await updateOrder({
                    variables: {
                        productId: Number(product?.id || 0),
                        data: {
                            order: {
                                update: {
                                    delivery: {
                                        connect: {
                                            id: Number(delivery.value)
                                        }
                                    },
                                    address: {
                                        set: addressValue
                                    },
                                    deadlineAt: {
                                        set: moment(deadlineAt).format('YYYY-MM-DD')
                                    },
                                    ...descriptionValue
                                }
                            }
                        }
                    }
                });

                notify.info(
                    <NotifyContent
                        title="Заказ успешно изменен"
                        text={
                            <>
                                Заказ № <b>{order?.id || ''}</b> изменен
                            </>
                        }
                    />
                );
            }

            if (isEditProduct) {
                const {
                    batchSize,
                    dieCuttingPositionsCount,
                    itemsPerSheetForSolidUVVarnishCount,
                    itemsPerSheetEmbossingCount,
                    embossedSheetLength,
                    laminationSheetLength,
                    laminatedProductsPerSheetCount,
                    piecesPerPrintedSheetCount,
                    gluingPointsCount,
                    productsOnTheStampCount,
                    stampNumber,
                    flatShare,
                    printFormat,
                    colorfulness,
                    fitting,
                    productName,
                    piecesPerSilkscreenSheetCount
                } = data;

                const nameValue = usingValue(productName, {
                    name: {
                        set: productName
                    }
                });
                const batchSizeValue = usingValue(
                    batchSize,
                    {
                        batchSize: {
                            set: batchSize
                        }
                    },
                    {
                        batchSize: {
                            set: null
                        }
                    }
                );
                const filmTypeValue = usingValue(
                    filmType?.value,
                    {
                        film: {
                            connect: {
                                id: Number(filmType?.value)
                            }
                        }
                    },
                    {
                        film: {
                            disconnect: true
                        }
                    }
                );
                const laminatedProductsPerSheetCountValue = usingValue(
                    laminatedProductsPerSheetCount,
                    {
                        laminatedProductsPerSheetCount: {
                            set: laminatedProductsPerSheetCount
                        }
                    },
                    {
                        laminatedProductsPerSheetCount: {
                            set: null
                        }
                    }
                );
                const laminationSheetLengthValue = usingValue(
                    laminationSheetLength,
                    {
                        laminationSheetLength: {
                            set: laminationSheetLength
                        }
                    },
                    {
                        laminationSheetLength: {
                            set: null
                        }
                    }
                );
                const foilValue = usingValue(
                    typeOfFoil?.value,
                    {
                        foil: {
                            connect: {
                                id: Number(typeOfFoil?.value)
                            }
                        }
                    },
                    {
                        foil: {
                            disconnect: true
                        }
                    }
                );
                const piecesPerSilkscreenSheetCountValue = usingValue(
                    piecesPerSilkscreenSheetCount,
                    {
                        piecesPerSilkscreenSheetCount: {
                            set: piecesPerSilkscreenSheetCount
                        }
                    },
                    {
                        piecesPerSilkscreenSheetCount: {
                            set: null
                        }
                    }
                );
                const itemsPerSheetEmbossingCountValue = usingValue(
                    itemsPerSheetEmbossingCount,
                    {
                        itemsPerSheetEmbossingCount: {
                            set: itemsPerSheetEmbossingCount
                        }
                    },
                    {
                        itemsPerSheetEmbossingCount: {
                            set: null
                        }
                    }
                );
                const embossedSheetLengthValue = usingValue(
                    embossedSheetLength,
                    {
                        embossedSheetLength: {
                            set: embossedSheetLength
                        }
                    },
                    {
                        embossedSheetLength: {
                            set: null
                        }
                    }
                );
                const gluingPointsCountValue = usingValue(
                    gluingPointsCount,
                    {
                        gluingPointsCount: {
                            set: gluingPointsCount
                        }
                    },
                    {
                        gluingPointsCount: {
                            set: null
                        }
                    }
                );
                const dieCuttingPositionsCountValue = usingValue(
                    dieCuttingPositionsCount,
                    {
                        dieCuttingPositionsCount: {
                            set: dieCuttingPositionsCount
                        }
                    },
                    {
                        dieCuttingPositionsCount: {
                            set: null
                        }
                    }
                );
                const itemsPerSheetForSolidUVVarnishCountValue = usingValue(
                    itemsPerSheetForSolidUVVarnishCount,
                    {
                        itemsPerSheetForSolidUVVarnishCount: {
                            set: itemsPerSheetForSolidUVVarnishCount
                        }
                    },
                    {
                        itemsPerSheetForSolidUVVarnishCount: {
                            set: null
                        }
                    }
                );
                const materialValue = usingValue(
                    materialId,
                    {
                        material:
                            materialId?.value === materialId?.label
                                ? {
                                      create: {
                                          name: materialId?.value || '',
                                          materialType: {
                                              connect: {
                                                  type: 'material'
                                              }
                                          }
                                      }
                                  }
                                : {
                                      connect: {
                                          id: Number(materialId?.value)
                                      }
                                  }
                    },
                    {
                        material: {
                            disconnect: true
                        }
                    }
                );
                const productionMachinesValue = usingValue(
                    machineId,
                    {
                        productionMachines: {
                            connect: {
                                id: Number(machineId?.value)
                            }
                        }
                    },
                    {
                        productionMachines: {
                            disconnect: true
                        }
                    }
                );
                const piecesPerPrintedSheetCountValue = usingValue(
                    piecesPerPrintedSheetCount,
                    {
                        piecesPerPrintedSheetCount: {
                            set: piecesPerPrintedSheetCount
                        }
                    },
                    {
                        piecesPerPrintedSheetCount: {
                            set: null
                        }
                    }
                );
                const fittingValue = usingValue(
                    fitting,
                    {
                        fitting: {
                            set: fitting
                        }
                    },
                    {
                        fitting: {
                            set: null
                        }
                    }
                );
                const flatShareValue = usingValue(
                    flatShare,
                    {
                        flatShare: {
                            set: flatShare
                        }
                    },
                    {
                        flatShare: {
                            set: null
                        }
                    }
                );
                const productsOnTheStampCountValue = usingValue(
                    productsOnTheStampCount,
                    {
                        productsOnTheStampCount: {
                            set: productsOnTheStampCount
                        }
                    },
                    {
                        productsOnTheStampCount: {
                            set: null
                        }
                    }
                );
                const stampNumberValue = usingValue(
                    stampNumber,
                    {
                        stampNumber: {
                            set: stampNumber
                        }
                    },
                    {
                        stampNumber: {
                            set: null
                        }
                    }
                );
                const printFormatValue = usingValue(
                    printFormat,
                    {
                        printFormat: {
                            set: printFormat
                        }
                    },
                    {
                        printFormat: {
                            set: null
                        }
                    }
                );
                const colorfulnessValue = usingValue(
                    colorfulness,
                    {
                        colorfulness: {
                            set: colorfulness
                        }
                    },
                    {
                        colorfulness: {
                            set: null
                        }
                    }
                );
                const varnishValue = {
                    varnish: {
                        set: varnish
                    }
                };

                await updateOrder({
                    variables: {
                        productId: Number(product?.id || 0),
                        data: {
                            productionDetail: {
                                update: {
                                    ...productionMachinesValue
                                }
                            },
                            ...nameValue,
                            ...batchSizeValue,
                            ...materialValue,
                            ...piecesPerPrintedSheetCountValue,
                            ...fittingValue,
                            ...flatShareValue,
                            ...productsOnTheStampCountValue,
                            ...stampNumberValue,
                            ...printFormatValue,
                            ...colorfulnessValue,
                            ...varnishValue,
                            ...filmTypeValue,
                            ...foilValue,
                            ...laminatedProductsPerSheetCountValue,
                            ...laminationSheetLengthValue,
                            ...itemsPerSheetEmbossingCountValue,
                            ...embossedSheetLengthValue,
                            ...gluingPointsCountValue,
                            ...dieCuttingPositionsCountValue,
                            ...itemsPerSheetForSolidUVVarnishCountValue,
                            ...piecesPerSilkscreenSheetCountValue
                        }
                    }
                });

                notify.info(
                    <NotifyContent
                        title="Изделие успешно изменено"
                        text={
                            <>
                                Изделие <b>{productName}</b> изменено
                            </>
                        }
                    />
                );
            }

            onClose();
        } catch (e) {
            notify.error(<NotifyContent title="Что-то пошло не так" />);
        } finally {
            onUpdate && onUpdate();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (product) {
            const {
                name,
                productType,
                material,
                size,
                film,
                foil,
                batchSize,
                fitting,
                flatShare,
                printFormat,
                colorfulness,
                varnish,
                productsOnTheStampCount,
                stampNumber,
                laminatedProductsPerSheetCount,
                laminationSheetLength,
                itemsPerSheetEmbossingCount,
                embossedSheetLength,
                gluingPointsCount,
                dieCuttingPositionsCount,
                itemsPerSheetForSolidUVVarnishCount,
                piecesPerPrintedSheetCount,
                piecesPerSilkscreenSheetCount
            } = product;

            const client = order?.client,
                delivery = order?.delivery,
                machine = product?.productionDetail?.productionMachines;

            if (!isNewProduct) {
                // @ts-ignore
                setValue('productName', name);
                productType && setValue('productType', { label: productType.type, value: String(productType.id) });
                material &&
                    material.alias &&
                    setValue('materialId', { label: material.alias, value: String(material?.id) });
                size && setValue('size', { label: size.name, value: String(size.id) });
                film && setValue('filmType', { label: film?.name || '', value: String(film?.id) });
                foil && setValue('typeOfFoil', { label: foil?.name || '', value: String(foil?.id) });
                setValue('batchSize', batchSize);
                setValue('piecesPerPrintedSheetCount', piecesPerPrintedSheetCount || 0);
                fitting && setValue('fitting', fitting);
                flatShare && setValue('flatShare', flatShare);
                printFormat && setValue('printFormat', printFormat);
                colorfulness && setValue('colorfulness', colorfulness);
                setValue('varnish', varnish);
                productsOnTheStampCount && setValue('productsOnTheStampCount', productsOnTheStampCount);
                stampNumber && setValue('stampNumber', stampNumber);
                setValue('laminatedProductsPerSheetCount', laminatedProductsPerSheetCount || undefined);
                setValue('laminationSheetLength', laminationSheetLength || undefined);
                setValue('itemsPerSheetEmbossingCount', itemsPerSheetEmbossingCount || undefined);
                setValue('embossedSheetLength', embossedSheetLength || undefined);
                setValue('gluingPointsCount', gluingPointsCount || undefined);
                setValue('dieCuttingPositionsCount', dieCuttingPositionsCount || undefined);
                setValue('itemsPerSheetForSolidUVVarnishCount', itemsPerSheetForSolidUVVarnishCount || undefined);
                setValue('piecesPerSilkscreenSheetCount', piecesPerSilkscreenSheetCount || undefined);
                machine &&
                    setValue('machineId', {
                        label: machine?.name,
                        value: String(machine?.id)
                    });
            }

            // @ts-ignore
            client &&
                setValue('clientId', {
                    label: client?.name || '',
                    value: String(client?.id)
                });
            setValue('address', order?.address || '');
            setValue('orderName', order?.name || '');
            delivery &&
                setValue('delivery', {
                    label: delivery?.name || '',
                    value: String(delivery?.id)
                });
            setValue('description', order?.description || undefined);
            setValue('deadlineAt', moment(order?.deadlineAt));
        }
    }, [product, order, setValue, isNewProduct]);

    useEffect(() => {
        if (isEditOrder) {
            trigger('productName');
        }
    }, [isEditOrder, trigger]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs>
                {(isEditOrder || isNew) && (
                    <Column width="100%">
                        <Row>
                            <Subtitle>Данные заказа</Subtitle>
                        </Row>
                        <InputWrapper>
                            <TextField
                                required
                                id={`${id}-orderName`}
                                label={orderMeta.orderName.label}
                                placeholder={orderMeta.orderName.placeholder}
                                errorText={errors.orderName?.message}
                                {...register('orderName')}
                                disabled={isEditOrder}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <ClientsCombobox
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.clientId.label}
                                            placeholder={orderMeta.clientId.placeholder}
                                            errorText={errors.clientId?.message}
                                            required
                                            portalSelector={portalRootSelector}
                                            disabled={isEditOrder}
                                        />
                                    );
                                }}
                                control={control}
                                name="clientId"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, name, value } }) => {
                                    return (
                                        <DatePicker<Moment>
                                            onChange={onChange}
                                            label={orderMeta.deadlineAt.label}
                                            placeholder={orderMeta.deadlineAt.placeholder}
                                            value={value}
                                            id={`${id}-deadlineAt`}
                                            name={name}
                                            minDate={moment().add(1)}
                                            returnedType="Moment"
                                            required
                                            autoComplete="off"
                                            errorText={errors.deadlineAt?.message}
                                        />
                                    );
                                }}
                                control={control}
                                name="deadlineAt"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DeliverySelector
                                            required
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.delivery.label}
                                            placeholder={orderMeta.delivery.placeholder}
                                            errorText={errors.delivery?.message}
                                            portalSelector={portalRootSelector}
                                            isClearable
                                        />
                                    );
                                }}
                                control={control}
                                name="delivery"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                required={isDeliverySelected}
                                disabled={!isDeliverySelected}
                                id={`${id}-address`}
                                label={orderMeta.address.label}
                                placeholder={orderMeta.address.placeholder}
                                errorText={hasAddressError ? requiredFieldMessage : undefined}
                                {...register('address')}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-description`}
                                type="textarea"
                                label={orderMeta.description.label}
                                placeholder={orderMeta.description.placeholder}
                                {...register('description')}
                                errorText={errors.description?.message}
                            />
                        </InputWrapper>
                    </Column>
                )}
                {(isEditProduct || isNew || isNewProduct) && (
                    <Column width="100%">
                        <Row>
                            <Subtitle>Данные изделия</Subtitle>
                        </Row>
                        <InputWrapper>
                            <TextField
                                type="text"
                                id={`${id}-productName`}
                                label={orderMeta.productName.label}
                                placeholder={orderMeta.productName.placeholder}
                                errorText={errors.productName?.message}
                                // @ts-ignore
                                {...register('productName')}
                                required
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <ProductTypeSelector
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.productType.label}
                                            placeholder={orderMeta.productType.placeholder}
                                            errorText={errors.productType?.message}
                                            required
                                            portalSelector={portalRootSelector}
                                            isClearable
                                            disabled={isEditProduct}
                                        />
                                    );
                                }}
                                control={control}
                                name="productType"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                required
                                id={`${id}-batchSize`}
                                type="number"
                                label={orderMeta.batchSize.label}
                                placeholder={orderMeta.batchSize.placeholder}
                                errorText={errors.batchSize?.message}
                                {...register('batchSize', { valueAsNumber: true })}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <SizesSelector
                                            onChange={onChange}
                                            label={orderMeta.size.label}
                                            value={value}
                                            placeholder={orderMeta.size.placeholder}
                                            errorText={errors.size?.message}
                                            required
                                            portalSelector={portalRootSelector}
                                            isClearable
                                            isAny
                                            disabled={isEditProduct}
                                        />
                                    );
                                }}
                                control={control}
                                name="size"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <MachinesCombobox
                                            value={value}
                                            onChange={onChange}
                                            label={orderMeta.machineId.label}
                                            placeholder={orderMeta.machineId.placeholder}
                                            errorText={errors.machineId?.message}
                                            portalSelector={portalRootSelector}
                                        />
                                    );
                                }}
                                control={control}
                                name="machineId"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <MaterialTypeSelector
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.materialId.label}
                                            placeholder={orderMeta.materialId.placeholder}
                                            errorText={errors.materialId?.message}
                                            portalSelector={portalRootSelector}
                                            isClearable
                                            type="material"
                                        />
                                    );
                                }}
                                control={control}
                                name="materialId"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-fitting`}
                                type="number"
                                label={orderMeta.fitting.label}
                                placeholder={orderMeta.fitting.placeholder}
                                {...register('fitting', { valueAsNumber: true })}
                                errorText={errors.fitting?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-flatShare`}
                                type="number"
                                label={orderMeta.flatShare.label}
                                placeholder={orderMeta.flatShare.placeholder}
                                {...register('flatShare', { valueAsNumber: true })}
                                errorText={errors.flatShare?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-printFormat`}
                                label={orderMeta.printFormat.label}
                                placeholder={orderMeta.printFormat.placeholder}
                                {...register('printFormat')}
                                errorText={errors.printFormat?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-colorfulness`}
                                label={orderMeta.colorfulness.label}
                                placeholder={orderMeta.colorfulness.placeholder}
                                {...register('colorfulness')}
                                errorText={errors.colorfulness?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <VarnishSelector
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.varnish.label}
                                            placeholder={orderMeta.varnish.placeholder}
                                            errorText={errors.varnish?.message}
                                            portalSelector={portalRootSelector}
                                            isClearable
                                        />
                                    );
                                }}
                                control={control}
                                name="varnish"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-productsOnTheStampCount`}
                                label={orderMeta.productsOnTheStampCount.label}
                                placeholder={orderMeta.productsOnTheStampCount.placeholder}
                                type="number"
                                {...register('productsOnTheStampCount', { valueAsNumber: true })}
                                errorText={errors.productsOnTheStampCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-stampNumber`}
                                label={orderMeta.stampNumber.label}
                                placeholder={orderMeta.stampNumber.placeholder}
                                type="text"
                                {...register('stampNumber')}
                                errorText={errors.stampNumber?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-piecesPerPrintedSheetCount`}
                                label={orderMeta.piecesPerPrintedSheetCount.label}
                                placeholder={orderMeta.piecesPerPrintedSheetCount.placeholder}
                                type="number"
                                {...register('piecesPerPrintedSheetCount', { valueAsNumber: true })}
                                errorText={errors.piecesPerPrintedSheetCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <MaterialTypeSelector
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.filmType.label}
                                            placeholder={orderMeta.filmType.placeholder}
                                            errorText={errors.filmType?.message}
                                            portalSelector={portalRootSelector}
                                            isClearable
                                            type="film"
                                        />
                                    );
                                }}
                                control={control}
                                name="filmType"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-laminatedProductsPerSheetCount`}
                                label={orderMeta.laminatedProductsPerSheetCount.label}
                                placeholder={orderMeta.laminatedProductsPerSheetCount.placeholder}
                                type="number"
                                {...register('laminatedProductsPerSheetCount', { valueAsNumber: true })}
                                errorText={errors.laminatedProductsPerSheetCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-laminationSheetLength`}
                                label={orderMeta.laminationSheetLength.label}
                                placeholder={orderMeta.laminationSheetLength.placeholder}
                                type="number"
                                {...register('laminationSheetLength', { valueAsNumber: true })}
                                errorText={errors.laminationSheetLength?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <Controller
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <MaterialTypeSelector
                                            onChange={onChange}
                                            value={value}
                                            label={orderMeta.typeOfFoil.label}
                                            placeholder={orderMeta.typeOfFoil.placeholder}
                                            errorText={errors.typeOfFoil?.message}
                                            portalSelector={portalRootSelector}
                                            isClearable
                                            type="foil"
                                        />
                                    );
                                }}
                                control={control}
                                name="typeOfFoil"
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-piecesPerSilkscreenSheetCount`}
                                label={orderMeta.piecesPerSilkscreenSheetCount.label}
                                placeholder={orderMeta.piecesPerSilkscreenSheetCount.placeholder}
                                type="number"
                                {...register('piecesPerSilkscreenSheetCount', { valueAsNumber: true })}
                                errorText={errors.piecesPerSilkscreenSheetCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-itemsPerSheetEmbossingCount`}
                                label={orderMeta.itemsPerSheetEmbossingCount.label}
                                placeholder={orderMeta.itemsPerSheetEmbossingCount.placeholder}
                                type="number"
                                {...register('itemsPerSheetEmbossingCount', { valueAsNumber: true })}
                                errorText={errors.itemsPerSheetEmbossingCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-embossedSheetLength`}
                                label={orderMeta.embossedSheetLength.label}
                                placeholder={orderMeta.embossedSheetLength.placeholder}
                                type="number"
                                {...register('embossedSheetLength', { valueAsNumber: true })}
                                errorText={errors.embossedSheetLength?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-gluingPointsCount`}
                                label={orderMeta.gluingPointsCount.label}
                                placeholder={orderMeta.gluingPointsCount.placeholder}
                                type="number"
                                {...register('gluingPointsCount', { valueAsNumber: true })}
                                errorText={errors.gluingPointsCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-dieCuttingPositionsCount`}
                                label={orderMeta.dieCuttingPositionsCount.label}
                                placeholder={orderMeta.dieCuttingPositionsCount.placeholder}
                                type="number"
                                {...register('dieCuttingPositionsCount', { valueAsNumber: true })}
                                errorText={errors.dieCuttingPositionsCount?.message}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextField
                                id={`${id}-itemsPerSheetForSolidUVVarnishCount`}
                                label={orderMeta.itemsPerSheetForSolidUVVarnishCount.label}
                                placeholder={orderMeta.itemsPerSheetForSolidUVVarnishCount.placeholder}
                                type="number"
                                {...register('itemsPerSheetForSolidUVVarnishCount', { valueAsNumber: true })}
                                errorText={errors.itemsPerSheetForSolidUVVarnishCount?.message}
                            />
                        </InputWrapper>
                    </Column>
                )}
            </FormInputs>

            <Row width="100%" justifyEnd>
                <MarginWrapper marginRight="10px">
                    <Button onClick={onClose} colorTheme="secondary">
                        Отмена
                    </Button>
                </MarginWrapper>
                <Button disabled={!isValid || hasAddressError} loading={loading} type="submit">
                    {isEditOrder ? 'Сохранить' : 'Готово'}
                </Button>
            </Row>
        </Form>
    );
};
