import { HeaderMenuItem } from '@C/components/common/ui/tabs/HeaderMenu/types';
import { ItemKey } from '@/components/grid/Headers/MainHeader/types';
import { BoardIcon, CalendarIcon, PeopleIcon } from '@C/assets/icons';
import { clientsRoute, ordersRoute, schedulerRoute } from '@/router/routerNames';

export const headerHeight = '100px';

export const headerHorizontalPadding = '24px';

interface MenuItems<T> extends HeaderMenuItem<T> {
    path: string;
}

export const menuItems: MenuItems<ItemKey>[] = [
    {
        Icon: CalendarIcon,
        name: 'План заказов',
        key: 'scheduler',
        path: schedulerRoute
    },
    {
        Icon: PeopleIcon,
        name: 'Клиенты',
        key: 'clients',
        path: clientsRoute
    },
    {
        Icon: BoardIcon,
        name: 'Изделия',
        key: 'orders',
        path: ordersRoute
    }
];
