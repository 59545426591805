import { useEffect } from 'react';
import { modalEvents } from '@/stores/modals';
import { ClientForm } from '@/components/forms/ClientForm';
import { history } from '@/browserHistory';

const { openModal, closeModal } = modalEvents;

export const NewClient = () => {
    const onClose = () => {
        history.back();
        closeModal();
    };

    useEffect(() => {
        openModal({
            isOpen: true,
            title: 'Новый клиент',
            onClose,
            minWidth: '550px',
            content: <ClientForm onClose={onClose} />
        });

        return () => {
            closeModal();
        };
    }, []);

    return <></>;
};
