import { containerSize } from '@C/constants/styles/breackpoints';
import styled from 'styled-components';
import { mainContentHorizontalPadding } from '@/components/layouts/MainLayout/constants';

export const MainLayoutWrapper = styled.div``;

export const MainContent = styled.div`
    max-width: ${containerSize.xxxl}px;
    margin: 30px auto 0;
    padding: 0 ${mainContentHorizontalPadding};
`;
