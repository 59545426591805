import { createEvent, createStore } from 'effector';
import { ModalProps } from '@C/components/common/modals/Modal';
import { NoopFn } from '@/constants/common';

interface ModalStore extends Omit<ModalProps, 'buttons'> {
    content: JSX.Element;
}

const initialStore: ModalStore = {
    isOpen: false,
    onClose: NoopFn,
    title: '',
    content: <></>
};

export const modalFabric = <T extends object>(initialStore: T) => {
    const openModal = createEvent<Partial<T>>();
    const setModal = createEvent<Partial<T>>();
    const closeModal = createEvent();

    const defaultStoreValues = { ...initialStore, onClose: closeModal };

    const modal = createStore<T>(defaultStoreValues)
        .on(openModal, (state, newState) => ({
            ...state,
            ...newState,
            isOpen: true
        }))
        .on(setModal, (state, newState) => ({
            ...state,
            ...newState
        }))
        .on(closeModal, () => defaultStoreValues);

    return { openModal, setModal, closeModal, modal };
};

export const { openModal, modal, setModal, closeModal } = modalFabric<ModalStore>(initialStore);
