import * as yup from 'yup';
import {
    yupDefault,
    yupEmailStrict,
    yupPhoneStrict,
    yupReactSelectStrict,
    yupString
} from '@/constants/validation/yupFields';
import { ClientMeta } from './types';

export const yupSchema = yup.object({
    name: yupDefault,
    clientType: yupReactSelectStrict,
    inn: yupString,
    contact: yupDefault,
    phone: yupPhoneStrict,
    email: yupEmailStrict,
    address: yupDefault,
    documents: yupDefault
});

export const clientMeta: ClientMeta = {
    name: {
        label: 'ФИО',
        placeholder: 'ФИО'
    },
    clientType: {
        label: 'Тип клиента',
        placeholder: 'Тип клиента'
    },
    inn: {
        label: 'ИНН',
        placeholder: 'ИНН'
    },
    contact: {
        label: 'Контактное лицо',
        placeholder: 'Контактное лицо'
    },
    contact2: {
        label: 'Контактное лицо',
        placeholder: 'Контактное лицо'
    },
    contact3: {
        label: 'Контактное лицо',
        placeholder: 'Контактное лицо'
    },
    phone: {
        label: 'Телефон',
        placeholder: 'Телефон'
    },
    email: {
        label: 'Электронная почта',
        placeholder: 'Электронная почта'
    },
    address: {
        label: 'Адрес доставки',
        placeholder: 'Адрес доставки'
    },
    documents: {
        label: 'Требования к отгрузочным документам',
        placeholder: 'Требования к отгрузочным документам'
    }
};
