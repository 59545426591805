import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { TextField } from '@C/components/common/inputs/TextField';
import { forwardRef, useId, useMemo, useState } from 'react';
import { AccordionList } from '@C/components/common/ui/AccordionList';
import { AccordionItemI } from '@C/components/common/ui/AccordionList/types';
import { OnTextChange } from '@C/components/common/inputs/TextField/types';
import Fuse from 'fuse.js';
import { ListWrapper } from '@/components/contents/Scheduler/OrdersList/styles';
import { Loading, NoopGenericType } from '@C/types/data';
import { FilterIcon } from '@C/assets/icons';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';
import { ButtonStatusSelector } from '@/components/inputs/StatusSelector';
import { OnChangeFilterValues, OrdersOnSchedulerFilterValues } from '@/stores/filters/ordersOnSchedulerFilter';
import { Loader } from '@C/components/common/ui/Loader';

export interface OrdersListProps extends Loading {
    items: AccordionItemI[];
    onItemClick: NoopGenericType<string>;
    activeItemId?: string;
    orderFilterStatusValues: OrdersOnSchedulerFilterValues['status'];
    onOrderFilterChange: OnChangeFilterValues['onStatusChange'];
}

/* eslint-disable react/display-name */
export const OrdersList = forwardRef<HTMLDivElement, OrdersListProps>(
    ({ items, onItemClick, activeItemId, orderFilterStatusValues, onOrderFilterChange, loading }, listRef) => {
        const id = useId();

        const [searchValue, setSearchValue] = useState('');
        const onSearchChange: OnTextChange = e => setSearchValue(e.target.value);

        const indexedOrders = useMemo(
            () =>
                new Fuse(
                    items.sort((a, b) => Number(a.id) - Number(b.id)),
                    {
                        keys: ['name', 'child.name'],
                        threshold: 0.3
                    }
                ),
            [items]
        );

        const filteredOrderList = useMemo(
            () => (searchValue ? indexedOrders.search(searchValue).map(({ item }) => item) : items),
            [indexedOrders, items, searchValue]
        );

        const onOrderFilterOptionChange: OnSelectChange<true> = newValue => newValue && onOrderFilterChange(newValue);

        return (
            <Column width="100%">
                <Row width="100%" marginBottom="10px" alignCenter justifyBetween>
                    <Row width="70%">
                        <TextField
                            inputTheme="secondary"
                            id={`${id}-search`}
                            placeholder="Поиск"
                            value={searchValue}
                            onChange={onSearchChange}
                        />
                    </Row>
                    <Row>
                        <ButtonStatusSelector
                            isMulti
                            value={orderFilterStatusValues}
                            onChange={onOrderFilterOptionChange}
                            inputTheme="secondary"
                            buttonSelectIcon={
                                <Row zIndex="1">
                                    <FilterIcon />
                                </Row>
                            }
                            withAutoClear
                        />
                    </Row>
                </Row>
                {loading ? (
                    <Row width="100%" justifyCenter>
                        <Loader />
                    </Row>
                ) : (
                    <ListWrapper>
                        <AccordionList
                            ref={listRef}
                            activeItemId={activeItemId}
                            onItemClick={onItemClick}
                            items={filteredOrderList}
                        />
                    </ListWrapper>
                )}
            </Column>
        );
    }
);
