import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { useMaterialsByTypeLazyQuery } from '@/graphql/api.types';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { NoopType, ReactSelectOption } from '@C/types/data';
import { AddNewMaterialSLot, IconButtonWrapper } from '@/components/inputs/MaterialTypeSelector/styles';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { CommentBadgeIcon, MoreIcon, PlusIcon } from '@C/assets/icons';
import { Modal } from '@C/components/common/modals/Modal';
import { MaterialForm } from '@/components/forms/MaterialForm';

interface MaterialTypeSelectorProps extends Omit<SelectorProps, 'options'> {
    type: 'material' | 'film' | 'foil';
}

export const MaterialTypeSelector = ({ type, ...props }: MaterialTypeSelectorProps) => {
    const [modalState, setModalState] = useState({ isOpen: false, title: 'Создать материал', content: <></> });
    const onCloseModal = () => setModalState(modalState => ({ ...modalState, isOpen: false, content: <></> }));

    const isMaterialType = type === 'material';

    const [loadMaterials, { data }] = useMaterialsByTypeLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: {
            type
        }
    });

    const getMaterialById = (materialId: string) => data?.materials?.find(({ id }) => String(id) === materialId);

    const options: ReactSelectOption[] = (data?.materials || []).map(({ id, alias, name }) => ({
        label: isMaterialType ? alias || '' : name,
        value: id.toString()
    }));

    useEffect(() => {
        loadMaterials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectorRef = useRef<any>(null);

    const onMenuClose = selectorRef?.current?.props?.onMenuClose as NoopType;

    const onNewFirstListSlotClick = () => {
        setModalState({
            isOpen: true,
            title: 'Создать материал',
            content: <MaterialForm onUpdate={loadMaterials} type="new" onClose={onCloseModal} />
        });
        onMenuClose();
    };

    const onEditClick: (value: ReactSelectOption) => MouseEventHandler<HTMLDivElement> = value => event => {
        event.stopPropagation();
        setModalState({
            isOpen: true,
            title: 'Изменить материал',
            content: (
                <MaterialForm
                    onUpdate={loadMaterials}
                    type="edit"
                    onClose={onCloseModal}
                    material={getMaterialById(value.value)}
                />
            )
        });
        onMenuClose();
    };

    const onCopyClick: (value: ReactSelectOption) => MouseEventHandler<HTMLDivElement> = value => event => {
        event.stopPropagation();
        setModalState({
            isOpen: true,
            title: 'Копировать материал',
            content: (
                <MaterialForm
                    onUpdate={loadMaterials}
                    type="copy"
                    onClose={onCloseModal}
                    material={getMaterialById(value.value)}
                />
            )
        });
        onMenuClose();
    };

    if (isMaterialType) {
        return (
            <>
                <Selector
                    options={options}
                    ref={selectorRef}
                    firstListSlot={
                        <AddNewMaterialSLot onClick={onNewFirstListSlotClick}>
                            <Row>
                                <PlusIcon />
                            </Row>
                            <Row>
                                <span>
                                    <b>Создать </b> новый материал
                                </span>
                            </Row>
                        </AddNewMaterialSLot>
                    }
                    endOptionSlot={(value: ReactSelectOption) => {
                        return (
                            <Row width="60px" justifyBetween>
                                <IconButtonWrapper onClick={onEditClick(value)}>
                                    <MoreIcon />
                                </IconButtonWrapper>
                                <IconButtonWrapper onClick={onCopyClick(value)}>
                                    <CommentBadgeIcon />
                                </IconButtonWrapper>
                            </Row>
                        );
                    }}
                    {...props}
                />
                <Modal
                    isOpen={modalState.isOpen}
                    title={modalState.title}
                    maxHeight="340px"
                    minHeight="250px"
                    onClose={onCloseModal}
                >
                    {modalState.content}
                </Modal>
            </>
        );
    }

    return <Selector options={options} {...props} />;
};
