export const baseRoute = '/';
export const anyRoute = '*';
export const loginRoute = 'login';
export const ordersRoute = 'orders';
export const clientsRoute = 'clients';
export const schedulerRoute = 'scheduler';
export const newRoute = 'new';

export const to = (routeName: string) => `${baseRoute}${routeName}`;
export const toNew = (routeName: string) => to(routeName) + to(newRoute);
export const toAny = (routeName: string) => to(routeName) + to(anyRoute);
export const toRoute = (routeName: string) => `${routeName}${baseRoute}`;

export const defaultRoute = to(ordersRoute);

export const routeNames: { [key: string]: string } = {
    [ordersRoute]: 'Изделия',
    [clientsRoute]: 'Клиенты'
};
