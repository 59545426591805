import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { client } from '@/graphql/configureApolloClient';

const container = document.getElementById('root');

if (container) {
    const root = ReactDOMClient.createRoot(container);

    root.render(
        <>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </>
    );
}
