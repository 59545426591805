import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import styled from 'styled-components';

export const InputWrapper = styled(Row)`
    textarea {
        min-height: 100px;
    }
`;

export const FormInputs = styled.div`
    padding: 5px 10px 0 0;
    height: 580px;
    overflow: auto;
    margin-bottom: 20px;
    ${InputWrapper} {
        margin-bottom: 20px;

        :last-child {
            margin-bottom: 0;
        }
    }
`;
