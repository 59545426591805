import styled from 'styled-components';
import { HasError } from '@C/types/data';
import { errorColor } from '@C/constants/styles/colors';

export const Form = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
`;

export const InfoBadge = styled.span<HasError>`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    transition: color 0.1s ease-in-out;

    ${({ hasError }) => hasError && `color: ${errorColor}`}
`;
