import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { useOperationsLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { ReactSelectOption } from '@C/types/data';

type OperationsSelectorProps = Omit<SelectorProps, 'options'>;

export const OperationsSelector = (props: OperationsSelectorProps) => {
    const [loadOperations, { data }] = useOperationsLazyQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });

    const options: ReactSelectOption[] = (data?.operations || []).map(({ id, name }) => ({
        label: name,
        value: id.toString()
    }));

    useEffect(() => {
        loadOperations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Selector options={options} {...props} />;
};
