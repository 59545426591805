import { softGray2 } from '@C/constants/styles/colors';
import styled from 'styled-components';

interface VarnishIconProps {
    isActive?: boolean;
}

export const VarnishIcon = styled.div<VarnishIconProps>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    :after {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${({ isActive }) => (isActive ? '#ACEFA9' : softGray2)};
    }
`;
