import { DatePicker } from '@C/components/common/inputs/Datepicker';
import { useId } from 'react';
import { InputWrapper } from '@/components/layouts/FilterLayout/styles';
import { Moment } from 'moment';
import { FilterLayout } from '@/components/layouts/FilterLayout';
import { OnChangeSchedulerFilterValues, SchedulerFilterValues } from '@/stores/filters/schedulerFilter';
import { Selector } from '@C/components/common/inputs/Select';
import { calendarTypesOption } from '@/components/filters/SchedulerFilter/constants';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { filterEvents } from '@/stores/filters';
import { DeleteIcon } from '@C/assets/icons';

const { clearSchedulerFilter } = filterEvents;

interface SchedulerFilterProps {
    values: SchedulerFilterValues;
    onChanges: OnChangeSchedulerFilterValues;
}

export const SchedulerFilter = ({ values, onChanges }: SchedulerFilterProps) => {
    const id = useId();

    const { period, calendarType } = values;
    const { onPeriodChange, onCalendarTypeChange } = onChanges;

    return (
        <FilterLayout>
            <InputWrapper>
                <DatePicker<Moment>
                    label=""
                    type={calendarType?.value === 'week-detailed' ? 'week' : 'period'}
                    placeholder="Выберите период"
                    returnedType="Moment"
                    name={`${id}-period`}
                    onChange={onPeriodChange}
                    value={period}
                    inputTheme="secondary"
                    id={`${id}-period`}
                />
            </InputWrapper>
            <InputWrapper width="150px">
                <Selector
                    onChange={onCalendarTypeChange}
                    value={calendarType}
                    placeholder="Выберите тип"
                    inputTheme="secondary"
                    id={`${id}-status`}
                    options={calendarTypesOption}
                />
            </InputWrapper>
            <MarginWrapper>
                <Button onClick={clearSchedulerFilter} icon={<DeleteIcon />} colorTheme="secondary" />
            </MarginWrapper>
        </FilterLayout>
    );
};
