import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { useClientTypesLazyQuery } from '@/graphql/api.types';
import { useEffect } from 'react';
import { ReactSelectOption } from '@C/types/data';

type ClientTypeSelectorProps = Omit<SelectorProps, 'options'>;

export const ClientTypeSelector = (props: ClientTypeSelectorProps) => {
    const [loadClientType, { data }] = useClientTypesLazyQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });

    const options: ReactSelectOption[] = (data?.clientTypes || []).map(({ id, type }) => ({
        label: type,
        value: id.toString()
    }));

    useEffect(() => {
        loadClientType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Selector options={options} {...props} />;
};
