import { Selector, SelectorProps } from '@C/components/common/inputs/Select';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';
import { getVarnishOptionsByVarnishValue, varnishOptions } from '@/components/inputs/VarnishSelector/constants';
import { useCallback, useMemo } from 'react';

interface VarnishSelectorProps extends Omit<SelectorProps<true>, 'options' | 'value' | 'onChange'> {
    value?: boolean | null;
    onChange: (value: boolean | null) => void;
}

export const VarnishSelector = ({ value, onChange, ...props }: VarnishSelectorProps) => {
    const localValue = useMemo(() => getVarnishOptionsByVarnishValue(value), [value]);

    const onLocalChange = useCallback<OnSelectChange<false>>(
        option => {
            const returnedValue = option?.value === 'false' ? false : option?.value === 'true' ? true : null;

            onChange(returnedValue);
        },
        [onChange]
    );

    return <Selector<false> options={varnishOptions} onChange={onLocalChange} value={localValue} {...props} />;
};
