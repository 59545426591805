import { ColumnOriginType } from '@C/components/common/ui/tables/Table/types';
import { ClientsTableData } from '@/components/tables/ClientsTable/types';

export const columns: ColumnOriginType<ClientsTableData>[] = [
    { Header: '№', accessor: 'id', width: 30 },
    { Header: 'Клиент', accessor: 'name' },
    { Header: 'Контактное лицо', accessor: 'contact' },
    { Header: 'Телефон', accessor: 'phone' },
    { Header: 'Электронная почта', accessor: 'email' },
    { Header: 'Адрес доставки', accessor: 'address' }
];
