import { middleGray } from '@C/constants/styles/colors';
import styled from 'styled-components';

export const Delimiter = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${middleGray};
    margin: 20px 0;
`;

export const SchedulerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
`;

export const OrdersBoardWrapper = styled.div`
    width: 100%;
    height: 31vh;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
`;
