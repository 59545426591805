import { Column, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { OrderBoardTable } from '@/components/tables/OrderBoardTable';
import { OrderBoardTableData } from '@/components/tables/OrderBoardTable/types';
import { StageForm } from '@/components/forms/StageForm';
import { modalEvents } from '@/stores/modals';
import { OrderBoardTableWrapper } from '@/components/contents/Scheduler/OrdersBoard/styles';
import { OrdersList, OrdersListProps } from '@/components/contents/Scheduler/OrdersList';
import { OrderForScheduleInterface, ProductByIdType } from '@/components/contents/Scheduler/OrdersBoard/types';
import { AccordionItemI } from '@C/components/common/ui/AccordionList/types';
import { forwardRef, useMemo } from 'react';
import moment from 'moment';
import { convertDurationInMinToMoment } from '@C/utils/dateTimeUtils';
import { onRowClickType } from '@C/components/common/ui/tables/Table/types';
import { ProductScheduleType } from '@/components/forms/StageForm/types';
import { Loading, NoopGenericType, OnUpdateInterface } from '@C/types/data';
import { defaultDateTimeFormat } from '@C/constants/dateTime';
import { CommentBadgeIcon } from '@C/assets/icons';

const { openModal, closeModal } = modalEvents;

interface OrdersBoardProps
    extends OrderForScheduleInterface,
        OnUpdateInterface,
        Pick<OrdersListProps, 'onOrderFilterChange' | 'orderFilterStatusValues'>,
        Loading {
    activeProductId: string;
    setActiveProductId: NoopGenericType<string>;
}

/* eslint-disable react/display-name */
export const OrdersBoard = forwardRef<HTMLDivElement, OrdersBoardProps>(
    (
        {
            orders = [],
            onUpdate,
            activeProductId,
            setActiveProductId,
            onOrderFilterChange,
            orderFilterStatusValues,
            loading
        },
        listRef
    ) => {
        const productsById: ProductByIdType = useMemo(
            () =>
                orders.reduce((allProductsById, { products }) => {
                    const productsOnOrder = products.reduce(
                        (allProducts, product) => ({ ...allProducts, [product.id]: product }),
                        {}
                    );
                    return { ...allProductsById, ...productsOnOrder };
                }, {}),
            [orders]
        );

        const activeProduct = productsById[activeProductId];
        const activeSchedules = activeProduct?.schedules || [];

        const schedulesData: OrderBoardTableData[] = activeSchedules.map(
            ({ machine, status, operation, type, stageTime, endAt, startAt, id, description }) => ({
                stage: operation.name,
                type,
                equipment: machine?.name || '',
                stageTime: convertDurationInMinToMoment(stageTime).format('HH ч mm м'),
                startTime: startAt ? moment(startAt).format(defaultDateTimeFormat) : '',
                endTime: endAt ? moment(endAt).format(defaultDateTimeFormat) : '',
                status: status || '',
                info: description ? (
                    <Row zIndex="1000" data-tip={description}>
                        <CommentBadgeIcon />
                    </Row>
                ) : (
                    <></>
                ),
                id
            })
        );

        const ordersList: AccordionItemI[] = useMemo(
            () =>
                orders
                    .map(({ id, client, products }) => ({
                        name: `${id} ${client.name}`,
                        id: String(id),
                        child: products.map(({ name, id }) => ({ name, id: String(id) }))
                    }))
                    .filter(({ child }) => !!child.length),
            [orders]
        );

        const onRowClick: onRowClickType<OrderBoardTableData> = ({ id }) => {
            const schedule = activeSchedules.find(({ id: scheduleId }) => scheduleId === id),
                productSchedule: ProductScheduleType = {
                    id: schedule?.id || 0,
                    startAt: schedule?.startAt,
                    endAt: schedule?.endAt,
                    description: schedule?.description,
                    stageTime: schedule?.stageTime || 0,
                    operation: schedule!.operation!,
                    machine: schedule?.machine,
                    isComplit: !!schedule?.isComplit,
                    productStatus: []
                };

            openModal({
                isOpen: true,
                title: 'Детали этапа',
                onClose: closeModal,
                minWidth: '500px',
                content: (
                    <StageForm
                        type="editScheduler"
                        productSchedule={productSchedule}
                        productId={activeProduct.id}
                        onClose={closeModal}
                        onUpdate={onUpdate}
                    />
                )
            });
        };

        return (
            <>
                <Column height="100%" width="200px" marginRight="20px">
                    <OrdersList
                        ref={listRef}
                        activeItemId={activeProductId}
                        onItemClick={setActiveProductId}
                        items={ordersList}
                        onOrderFilterChange={onOrderFilterChange}
                        orderFilterStatusValues={orderFilterStatusValues}
                        loading={loading}
                    />
                </Column>
                <OrderBoardTableWrapper>
                    <div>
                        <OrderBoardTable onRowClick={onRowClick} data={schedulesData} />
                    </div>
                </OrderBoardTableWrapper>
            </>
        );
    }
);
