import { combine, createEvent, createStore } from 'effector';
import { OnChangePeriod, PeriodDateType } from '@C/components/common/inputs/Datepicker/types';
import { Moment } from 'moment';
import { OnSelectChange } from '@C/components/common/inputs/Select/types';
import { calendarTypesOption, defaultPeriodByCalendarType } from '@/components/filters/SchedulerFilter/constants';
import { CalendarTypeOption } from '@/components/filters/SchedulerFilter/types';
import { getDatesRangeInIsoWeekByDate } from '@C/utils/dateTimeUtils';

export interface SchedulerFilterValues {
    period?: PeriodDateType<Moment>;
    calendarType: CalendarTypeOption | null;
}

export interface OnChangeSchedulerFilterValues {
    onCalendarTypeChange: OnSelectChange;
    onPeriodChange: OnChangePeriod<Moment>;
}

const initialValues: SchedulerFilterValues = {
    period: defaultPeriodByCalendarType['week'] as PeriodDateType<Moment>,
    calendarType: calendarTypesOption[1]
};

export const setSchedulerFilterValue = createEvent<Partial<SchedulerFilterValues>>();
export const clearSchedulerFilter = createEvent();

const schedulerFilterValuesStore = createStore<SchedulerFilterValues>(initialValues)
    .on(setSchedulerFilterValue, (state, newState) => {
        let period = state.period;

        if (newState.calendarType?.value === 'week') {
            period = state.period?.[0]
                ? (getDatesRangeInIsoWeekByDate(state.period?.[0]) as PeriodDateType<Moment>)
                : (defaultPeriodByCalendarType['week'] as PeriodDateType<Moment>);
        }
        return { ...state, period, ...newState };
    })
    .on(clearSchedulerFilter, () => ({ ...initialValues }));

const onChangeSchedulerValuesStore = createStore<OnChangeSchedulerFilterValues>({
    onPeriodChange: period => setSchedulerFilterValue({ period: period || initialValues.period }),
    onCalendarTypeChange: calendarType => setSchedulerFilterValue({ calendarType: calendarType as CalendarTypeOption })
});

export const schedulerFilterStore = combine(schedulerFilterValuesStore, onChangeSchedulerValuesStore);
