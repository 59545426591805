import {
    ProductInfoLabels,
    ProductType,
    StageOptions,
    StageOptionsProps
} from '@/components/contents/Order/Product/types';
import { getVarnishOptionsByVarnishValue } from '@/components/inputs/VarnishSelector/constants';

export const stageOptions: ({ disabled }: StageOptionsProps) => StageOptions[] = ({ disabled }) => [
    { value: 'up', label: 'На строку вверх', isDisabled: disabled?.up },
    { value: 'down', label: 'На строку вниз', isDisabled: disabled?.down },
    { value: 'delete', label: 'Удалить этап', isDisabled: disabled?.delete },
    { value: 'detail', label: 'Детали этапа', isDisabled: disabled?.detail }
];

export const convertProductToViewList: (product?: ProductType) => ProductInfoLabels | undefined = product => {
    if (!product) return;

    const {
        name,
        productType,
        material,
        size,
        batchSize,
        piecesPerPrintedSheetCount,
        fitting,
        flatShare,
        printFormat,
        colorfulness,
        productsOnTheStampCount,
        stampNumber,
        film,
        laminatedProductsPerSheetCount,
        laminationSheetLength,
        foil,
        piecesPerSilkscreenSheetCount,
        itemsPerSheetEmbossingCount,
        embossedSheetLength,
        gluingPointsCount,
        dieCuttingPositionsCount,
        itemsPerSheetForSolidUVVarnishCount,
        varnish,
        calculatedFields: {
            batchSizePrintedSheets,
            flatsCount,
            passesOnThePunchingCount,
            sheetPassesForLaminationCount,
            filmsPerCirculationForLaminationCount,
            punchingPassesCount,
            foilsPerPrintRunCount,
            passesForScreenPrintingCount,
            sheetPassesOnContinuousUVVarnishCount
        }
    } = product;

    return {
        name,
        productType: productType?.type,
        material: material?.alias || '',
        batchSizePrintedSheets: batchSizePrintedSheets,
        size: size.name,
        batchSize,
        piecesPerPrintedSheetCount,
        fitting,
        flatsCount,
        flatShare,
        printFormat,
        colorfulness,
        varnish: getVarnishOptionsByVarnishValue(varnish)?.label,
        productsOnTheStampCount,
        stampNumber,
        passesOnThePunchingCount,
        film: film?.name,
        laminatedProductsPerSheetCount,
        sheetPassesForLaminationCount,
        laminationSheetLength,
        filmsPerCirculationForLaminationCount,
        foil: foil?.name,
        piecesPerSilkscreenSheetCount,
        passesForScreenPrintingCount,
        itemsPerSheetEmbossingCount,
        embossedSheetLength,
        foilsPerPrintRunCount,
        gluingPointsCount,
        dieCuttingPositionsCount,
        punchingPassesCount,
        itemsPerSheetForSolidUVVarnishCount,
        sheetPassesOnContinuousUVVarnishCount
    };
};

export const showedOrderInfoLabels = {
    name: 'Наименование заказа',
    client: 'Клиент',
    address: 'Адрес',
    delivery: 'Доставка',
    description: 'Описание',
    deadlineAt: 'Дата сдачи заказа',
    machine: 'Номер машины'
};

export const showedProductInfoLabels = {
    name: 'Наименование изделия',
    productType: 'Тип изделия',
    material: 'Материал (Н/П/Ф)',
    batchSizePrintedSheets: 'Тираж печатных листов, шт',
    size: 'Размер готового изделия',
    batchSize: 'Тираж',
    piecesPerPrintedSheetCount: 'Количество штук на печатном листе',
    fitting: 'Приладка',
    flatsCount: 'Количество флатов, шт',
    flatShare: 'Доля флата',
    printFormat: 'Формат печати',
    colorfulness: 'Красочность',
    varnish: 'Лаковое покрытие',
    productsOnTheStampCount: 'Количество изделий на штампе',
    stampNumber: 'Номер штампа',
    passesOnThePunchingCount: 'Количество прогонов на вырубке',
    film: 'Вид фольги (Тиснение)',
    laminatedProductsPerSheetCount: 'Количество изделий на листе (Ламинация)',
    sheetPassesForLaminationCount: 'Количество листопрогонов на ламинации',
    laminationSheetLength: 'Длина листа ламинации',
    filmsPerCirculationForLaminationCount: 'Количество пленки на тираж (Ламинация)',
    foil: 'Вид пленки',
    piecesPerSilkscreenSheetCount: 'Количество изделий на листе для шелкографии',
    passesForScreenPrintingCount: 'Количество листопрогонов для шелкографии',
    itemsPerSheetEmbossingCount: 'Количество изделий на листе (Тиснение)',
    embossedSheetLength: 'Длина листа на тиснении',
    foilsPerPrintRunCount: 'Количество фольги на тираж',
    gluingPointsCount: 'Количество точек склейки',
    dieCuttingPositionsCount: 'Количество позиций для высечки',
    punchingPassesCount: 'Количество прогонов по высечке',
    itemsPerSheetForSolidUVVarnishCount: 'Количество изделий на листе для УФ-лака сплошного',
    sheetPassesOnContinuousUVVarnishCount: 'Количество листопрогонов на УФ-лаке сплошном'
};

export const noSet = 'Не задано';
