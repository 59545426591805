import { NoopType, OnUpdateInterface, ReactSelectOption } from '@C/types/data';
import { Form } from '@/components/forms/OrderForm/styles';
import { FormInputs, InputWrapper } from '@/components/forms/StatusForm/styles';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';
import { Button } from '@C/components/common/buttons/Button';
import { useId, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { statusMeta, yupSchema } from '@/components/forms/StatusForm/constants';
import { StatusInputs } from '@/components/forms/StatusForm/types';
import { portalRootSelector } from '@/constants/common';
import { TextField } from '@C/components/common/inputs/TextField';
import { Selector } from '@C/components/common/inputs/Select';
import { ProductScheduleInterface } from '@/components/forms/StageForm/types';
import { useAddStatusMutation, useStatusesByOperationQuery } from '@/graphql/api.types';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';

interface StageFormProps extends OnUpdateInterface, ProductScheduleInterface {
    onClose: NoopType;
}

export const StatusForm = ({ onClose, onUpdate, productSchedule }: StageFormProps) => {
    const id = useId();
    const [loading, setLoading] = useState(false);
    const operationId = productSchedule?.operation?.id || 0;
    const scheduleId = productSchedule?.id || 0;

    const { data: statusesData } = useStatusesByOperationQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: { operationId }
    });

    const statusesOption: ReactSelectOption[] = (statusesData?.operation?.statuses || []).map(({ status }) => ({
        label: status.name,
        value: String(status.id)
    }));

    const [addStatus] = useAddStatusMutation();

    const {
        register,
        handleSubmit,
        control,
        formState: { isValid, isDirty, errors }
    } = useForm<StatusInputs>({ resolver: yupResolver(yupSchema), mode: 'onChange' });

    const onSubmit: SubmitHandler<StatusInputs> = async ({ comment, status }) => {
        try {
            setLoading(true);
            await addStatus({ variables: { scheduleId, description: comment, statusId: Number(status?.value) } });

            notify.info(
                <NotifyContent
                    title="Статус успешно изменен"
                    text={
                        <>
                            Установлен статус <b>{status.label || ''}</b>
                        </>
                    }
                />
            );

            onClose();
        } catch {
            notify.error(<NotifyContent title="Что-то пошло не так" />);
        } finally {
            onUpdate && onUpdate();
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs>
                <InputWrapper width="50%">
                    <Controller
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Selector
                                    onChange={onChange}
                                    value={value}
                                    label={statusMeta.status.label}
                                    placeholder={statusMeta.status.placeholder}
                                    errorText={errors.status?.message}
                                    inputTheme="secondary"
                                    id={`${id}-status`}
                                    isClearable
                                    required
                                    portalSelector={portalRootSelector}
                                    options={statusesOption}
                                />
                            );
                        }}
                        control={control}
                        name="status"
                    />
                </InputWrapper>
                <InputWrapper>
                    <TextField
                        id={`${id}-comment`}
                        type="textarea"
                        label={statusMeta.comment.label}
                        placeholder={statusMeta.comment.placeholder}
                        {...register('comment')}
                        errorText={errors.comment?.message}
                        required
                    />
                </InputWrapper>
            </FormInputs>

            <Row width="100%" justifyEnd>
                <MarginWrapper marginRight="10px">
                    <Button onClick={onClose} colorTheme="secondary">
                        Отмена
                    </Button>
                </MarginWrapper>
                <Button disabled={!isValid || !isDirty} loading={loading} type="submit">
                    Сохранить
                </Button>
            </Row>
        </Form>
    );
};
