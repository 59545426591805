import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { LightButton } from '@C/components/common/buttons/LightButton';
import { NextArrow3Icon, PreviousArrow3Icon } from '@C/assets/icons';
import { NoopType } from '@C/types/data';

interface ControlPageButtonsProps {
    onNext?: NoopType;
    onPrevious?: NoopType;
}

export const ControlPageButtons = ({ onNext, onPrevious }: ControlPageButtonsProps) => {
    return (
        <Row width="250px" justifyBetween>
            <LightButton onClick={onPrevious} disabled={!onPrevious} preIcon={<PreviousArrow3Icon />}>
                Предыдущий
            </LightButton>
            <LightButton onClick={onNext} disabled={!onNext} postIcon={<NextArrow3Icon />}>
                Следующий
            </LightButton>
        </Row>
    );
};
