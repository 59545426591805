import { Table } from '@C/components/common/ui/tables/Table';
import { onRowClickType } from '@C/components/common/ui/tables/Table/types';
import { columns, getIconByVarnish, iconByStatusMapper, rowTypeFn } from '@/components/tables/OrdersTable/constants';
import { Fragment, useCallback, useMemo } from 'react';
import { OrdersTableData } from '@/components/tables/OrdersTable/types';
import { OrdersQuery } from '@/graphql/api.types';
import moment from 'moment';
import { defaultDateDayMonthFormat } from '@C/constants/dateTime';
import { OnChangeFilterValues, OrdersFilterValues } from '@/stores/filters/ordersFilter';
import { getMaterialName } from '@/components/inputs/MaterialTypeSelector/constants';

interface OrdersTableProps
    extends Pick<OrdersFilterValues, 'orderByState'>,
        Pick<OnChangeFilterValues, 'onOrderByChange'> {
    data?: OrdersQuery['products'];
    onOrderClick: (id: number) => void;
}

export const OrdersTable = ({ data = [], onOrderClick, orderByState, onOrderByChange }: OrdersTableProps) => {
    const rowMemoTypeFn = useCallback(rowTypeFn, []);

    const tableData: OrdersTableData[] = useMemo(
        () =>
            data.map(
                ({
                    order: { status, deadlineAt, client },
                    id,
                    name,
                    orderId,
                    batchSize,
                    productionDetail,
                    material,
                    batchSizePrintedSheets,
                    printFormat,
                    colorfulness,
                    varnish,
                    piecesPerPrintedSheetCount,
                    productSchedules
                }) => ({
                    icon: iconByStatusMapper[status.name || ''] || Fragment,
                    id: orderId,
                    productId: id,
                    name: client.name,
                    productName: name,
                    date: moment(deadlineAt).format(defaultDateDayMonthFormat),
                    batchSize: String(batchSize),
                    materialFormat: material?.format || '',
                    material: getMaterialName({ name: material?.name || '', density: material?.density }),
                    batchSizePrintedSheets: batchSizePrintedSheets ? String(batchSizePrintedSheets) : '',
                    printFormat: printFormat || '',
                    colorfulness: colorfulness || '',
                    varnish: getIconByVarnish(varnish),
                    piecesPerPrintedSheetCount: piecesPerPrintedSheetCount ? String(piecesPerPrintedSheetCount) : '',
                    status: status.name || '',
                    productSchedulesLength: String(productSchedules.length || 0),
                    operationName: productionDetail?.operationStatus.schedule.operation.name || ''
                })
            ),
        [data]
    );

    const onRowClick: onRowClickType<OrdersTableData> = row => onOrderClick(row.id);

    return (
        <Table<OrdersTableData>
            onRowClick={onRowClick}
            columns={columns}
            data={tableData}
            rowTypeFn={rowMemoTypeFn}
            withSticky
            withSort
            manualSort
            onSortChange={onOrderByChange}
            sortState={orderByState}
        />
    );
};
