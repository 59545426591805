import { PageLayout } from '@/components/layouts/PageLayout';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { SchedulerTimeline } from '@/components/contents/Scheduler/SchedulerTimeline';
import { Delimiter, OrdersBoardWrapper, SchedulerWrapper } from './styles';
import { OrdersBoard } from '@/components/contents/Scheduler/OrdersBoard';
import { useOrderPlanQuery, useOrdersForScheduleQuery } from '@/graphql/api.types';
import { defaultDateTimeServerFormat } from '@C/constants/dateTime';
import { getAccordionItemId } from '@C/components/common/ui/AccordionList/constants';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SchedulerFilter } from '@/components/filters/SchedulerFilter';
import { useStore } from 'effector-react';
import { filterStores } from '@/stores/filters';

export const Scheduler = () => {
    const [filterValues, onChanges] = useStore(filterStores.schedulerFilterStore);
    const { calendarType, period } = filterValues;

    const [ordersFilterValues, ordersQueryParams, onOrderFilterChanges] = useStore(
        filterStores.ordersOnSchedulerFilterStore
    );

    const {
        data: orders,
        loading: loadingOrdersForSchedule,
        refetch: refetchOrdersForSchedule
    } = useOrdersForScheduleQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: { ...ordersQueryParams }
    });

    const [activeScheduleId, setActiveScheduleId] = useState<string | undefined>();
    const [activeProductId, setActiveProductId] = useState<string>('');
    const listRef = useRef<HTMLDivElement>(null);

    const setActiveProductIdByScheduleId = useCallback(
        (scheduleId: string | undefined) => {
            if (scheduleId) {
                let mayBeId;

                orders?.ordersForSchedule?.forEach(({ products }) =>
                    products.forEach(({ schedules, id: productId }) =>
                        schedules.map(({ id }) => {
                            if (String(id) === scheduleId) {
                                mayBeId = String(productId);
                            }
                        })
                    )
                );
                mayBeId && setActiveProductId(mayBeId);

                return mayBeId;
            }
        },
        [orders?.ordersForSchedule]
    );

    const setActiveElement = useCallback(
        (newId: string | undefined) => {
            const newScheduleId = activeScheduleId === newId ? undefined : newId;
            setActiveScheduleId(newScheduleId);
            const newProductId = setActiveProductIdByScheduleId(newScheduleId);

            if (listRef.current && newProductId) {
                const orderItem = listRef.current.querySelector(`#${getAccordionItemId(newProductId)}`);
                orderItem?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }
        },
        [activeScheduleId, setActiveProductIdByScheduleId]
    );

    const onToggleActiveSchedule = useCallback((newId: string) => setActiveElement(newId), [setActiveElement]);
    const {
        data: orderPlan,
        loading: loadingOrderPlan,
        refetch: refetchOrderPlan
    } = useOrderPlanQuery({
        variables: {
            startAt: period?.[0].clone().utc().format(defaultDateTimeServerFormat),
            endAt: period?.[1]!.clone().utc().format(defaultDateTimeServerFormat)
        }
    });

    useEffect(() => {
        setActiveElement(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersQueryParams]);

    const onUpdate = () => {
        refetchOrderPlan();
        refetchOrdersForSchedule();
    };

    const filter = useMemo(
        () => <SchedulerFilter onChanges={onChanges} values={filterValues} />,
        [filterValues, onChanges]
    );

    return (
        <PageLayout title="План заказов" filter={filter}>
            <SchedulerWrapper>
                <Row width="100%" height="40vh">
                    <SchedulerTimeline
                        orderPlan={orderPlan?.getOrderPlan}
                        value={period}
                        calendarType={calendarType?.value}
                        loading={loadingOrderPlan}
                        onPeriodChange={onChanges.onPeriodChange}
                        activeScheduleId={activeScheduleId}
                        onToggleActiveSchedule={onToggleActiveSchedule}
                    />
                </Row>
                <Delimiter />
                <OrdersBoardWrapper>
                    <OrdersBoard
                        activeProductId={activeProductId}
                        setActiveProductId={setActiveProductId}
                        onUpdate={onUpdate}
                        orders={orders?.ordersForSchedule}
                        ref={listRef}
                        orderFilterStatusValues={ordersFilterValues.status}
                        onOrderFilterChange={onOrderFilterChanges.onStatusChange}
                        loading={loadingOrdersForSchedule}
                    />
                </OrdersBoardWrapper>
            </SchedulerWrapper>
        </PageLayout>
    );
};
