import { NoopType } from '@C/types/data';

export const NoopFn: NoopType = () => ({});

export const portalRootSelector = 'body';

const domainRegExp = new RegExp(/^(http|https):\/\/(.*?)(\/)/, 'g');

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '';
export const BACKEND_URL = domainRegExp.exec(REACT_APP_API_URL)?.[0];
