import styled from 'styled-components';

export const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    margin-right: 10px;
`;
