import { history } from '@/browserHistory';
import { to } from '@/router/routerNames';
import { useMemo } from 'react';

export const useAround = <T extends { id: number }>(searchedId?: string, data?: T[], toBase?: string) => {
    const array = data || [];

    const currentIndex = array.findIndex(({ id }) => String(id) === searchedId);

    let nextIndex = currentIndex + 1,
        nextValue: T | undefined,
        previousIndex = currentIndex - 1,
        previousValue: T | undefined;

    while (nextIndex < array.length && !nextValue) {
        const mayBeNext = array[nextIndex];
        if (String(mayBeNext.id) !== searchedId) {
            nextValue = mayBeNext;
        }
        nextIndex++;
    }

    while (previousIndex >= 0 && !previousValue) {
        const mayBePrevious = array[previousIndex];
        if (String(mayBePrevious.id) !== searchedId) {
            previousValue = mayBePrevious;
        }
        previousIndex++;
    }

    const hasNext = !!nextValue,
        hasPrevious = !!previousValue;

    const onNext = useMemo(
        () => (hasNext ? () => history.push(toBase + to(String(nextValue?.id))) : undefined),
        [hasNext, nextValue, toBase]
    );
    const onPrevious = useMemo(
        () => (hasPrevious ? () => history.push(toBase + to(String(previousValue?.id))) : undefined),
        [hasPrevious, previousValue, toBase]
    );

    return { onNext, onPrevious };
};
