import { FilterWrapper } from './styles';
import { Button } from '@C/components/common/buttons/Button';
import { FC } from '@C/types/global';
import { CreateClick } from '@/components/layouts/FilterLayout/types';
import { MarginWrapper } from '@C/components/wrappers/grid/MarginWrapper';

type FilterLayoutProps = CreateClick;

export const FilterLayout: FC<FilterLayoutProps> = ({ children, onCreateClick }) => {
    return (
        <FilterWrapper width="100%" alignCenter justifyEnd>
            {children}
            {onCreateClick && (
                <MarginWrapper marginLeft="20px">
                    <Button onClick={onCreateClick}>Создать</Button>
                </MarginWrapper>
            )}
        </FilterWrapper>
    );
};
