import { FC } from '@C/types/global';
import { FlexGrow, Row } from '@C/components/wrappers/grid/FlexWrapper';
import { LayoutWrapper, Title } from './styles';

interface PageLayoutProps {
    filter?: JSX.Element;
    title: string;
}

export const PageLayout: FC<PageLayoutProps> = ({ filter, children, title }) => (
    <LayoutWrapper>
        <Row width="100%" alignCenter marginBottom="40px" noWrap>
            <Title>{title}</Title>
            {filter && <FlexGrow flexDirection="row">{filter}</FlexGrow>}
        </Row>
        {children}
    </LayoutWrapper>
);
