import { BreadcrumbLayout } from '@/components/layouts/BreadcrumbLayout';
import { Row } from '@C/components/wrappers/grid/FlexWrapper';
import { useParams } from 'react-router-dom';
import { OrderParams } from '@/router/types';
import { Title } from '@/pages/Orders/Order/styles';
import { ControlPageButtons } from '@/components/grid/ControlPageButtons';
import { OrderContent } from '@/components/contents/Order/OrderContent';
import { useCallback, useEffect, useMemo } from 'react';
import { Loader } from '@C/components/common/ui/Loader';
import { useAround } from '@/hooks/around';
import { ordersRoute, to } from '@/router/routerNames';
import {
    useCancelOrderMutation,
    useCreateRelationScheduleMutation,
    useDeleteProductMutation,
    useDeleteRelationScheduleMutation,
    useDeleteScheduleMutation,
    useDownPriorityMutation,
    useExportProductMutation,
    useNextAndPreviousOrderIdLazyQuery,
    useOrderByIdLazyQuery,
    useUpPriorityMutation
} from '@/graphql/api.types';
import { OnCreateRelationScheduleType, OnOrderActionsType } from '@/components/contents/Order/OrderContent/types';
import { notify, NotifyContent } from '@C/components/common/ui/Notifications';
import { closeConfirmationModal } from '@/stores/modals/confirmationModal';
import { history } from '@/browserHistory';
import { BACKEND_URL } from '@/constants/common';
import { goToHrefLocation } from '@/utils/browser';

export const Order = () => {
    const { orderId } = useParams<OrderParams>();

    const [loadNextAndPreviousOrderId, { data: nextAndPreviousData }] = useNextAndPreviousOrderIdLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite'
    });

    const orderIds = [
        nextAndPreviousData?.orderNextAndPreviousId?.previous,
        orderId,
        nextAndPreviousData?.orderNextAndPreviousId?.next
    ]
        .filter(value => !!value)
        .map(value => ({ id: value })) as Array<{ id: number }>;

    const { onNext, onPrevious } = useAround(orderId, orderIds, to(ordersRoute));

    const [loadOrderById, { data, loading, refetch }] = useOrderByIdLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite'
    });

    const [deleteProduct] = useDeleteProductMutation();
    const [deleteSchedule] = useDeleteScheduleMutation();
    const [upPriority] = useUpPriorityMutation();
    const [downPriority] = useDownPriorityMutation();
    const [createRelationSchedule] = useCreateRelationScheduleMutation();
    const [deleteRelationSchedule] = useDeleteRelationScheduleMutation();
    const [cancelOrder] = useCancelOrderMutation();
    const [exportProduct] = useExportProductMutation();

    const onUpdate = useCallback(() => refetch(), [refetch]);

    const onCancelOrder = useCallback(async () => {
        const orderId = data?.order?.id || 0;

        try {
            await cancelOrder({ variables: { orderId } });

            notify.success(
                <NotifyContent
                    title="Заказ успешно отменен"
                    text={
                        <>
                            Заказ <b>№{orderId}</b> успешно отменен
                        </>
                    }
                />
            );
            closeConfirmationModal();
            history.push(to(ordersRoute));
        } catch {
            notify.error(<NotifyContent title="Что-то пошло не так" />);
        }
    }, [cancelOrder, data?.order?.id]);
    const onDeleteProduct = useCallback(
        async (productId: number) => {
            await deleteProduct({ variables: { productId } });
        },
        [deleteProduct]
    );
    const onDeleteSchedule = useCallback(
        async (scheduleId: number) => {
            try {
                await deleteSchedule({ variables: { scheduleId } });
            } catch (e) {
                const message = (e as { message: string })?.message;
                notify.error(<NotifyContent title={message || 'Что-то пошло не так'} />);
            }
        },
        [deleteSchedule]
    );
    const onUpPriority = useCallback(
        async (scheduleId: number) => {
            try {
                await upPriority({ variables: { scheduleId } });
            } catch (e) {
                const message = (e as { message: string })?.message;
                notify.error(<NotifyContent title={message || 'Что-то пошло не так'} />);
            }
        },
        [upPriority]
    );
    const onDownPriority = useCallback(
        async (scheduleId: number) => {
            try {
                await downPriority({ variables: { scheduleId } });
            } catch (e) {
                const message = (e as { message: string })?.message;
                notify.error(<NotifyContent title={message || 'Что-то пошло не так'} />);
            }
        },
        [downPriority]
    );

    const onCreateRelationSchedule: OnCreateRelationScheduleType = useCallback(
        async ({ relatedScheduleId, currentScheduleId }) =>
            await createRelationSchedule({ variables: { currentScheduleId, relatedScheduleId } }),
        [createRelationSchedule]
    );
    const onDeleteRelationSchedule = useCallback(
        async (relatedScheduleId: number) => await deleteRelationSchedule({ variables: { relatedScheduleId } }),
        [deleteRelationSchedule]
    );

    const onOrderActions: OnOrderActionsType = useMemo(
        () => ({
            onDeleteSchedule,
            onDownPriority,
            onUpPriority,
            onDeleteRelationSchedule,
            onCreateRelationSchedule
        }),
        [onCreateRelationSchedule, onDeleteRelationSchedule, onDeleteSchedule, onDownPriority, onUpPriority]
    );

    const onExportProduct = useCallback(
        async (productId: number) => {
            try {
                const exportProductData = await exportProduct({ variables: { productId } }),
                    productFilePath = exportProductData?.data?.exportProduct?.filePath;

                goToHrefLocation(BACKEND_URL, productFilePath);

                notify.success(
                    <NotifyContent
                        title="Продукт успешно экспортирован"
                        text={
                            <>
                                Продукт <b>№{productId}</b> успешно экспортирован
                            </>
                        }
                    />
                );
            } catch (e) {
                const message = (e as { message: string })?.message;
                notify.error(<NotifyContent title={message || 'Что-то пошло не так'} />);
            }
        },
        [exportProduct]
    );

    useEffect(() => {
        loadOrderById({ variables: { id: Number(orderId) } });
    }, [loadOrderById, orderId]);

    useEffect(() => {
        loadNextAndPreviousOrderId({ variables: { id: Number(orderId) } });
    }, [loadNextAndPreviousOrderId, orderId]);

    return (
        <BreadcrumbLayout>
            <Row width="100%" justifyBetween alignCenter marginBottom="40px">
                <Row>
                    <Title>
                        №{orderId} {data?.order?.name}
                    </Title>
                </Row>
                <Row>
                    <ControlPageButtons onNext={onNext} onPrevious={onPrevious} />
                </Row>
            </Row>
            <Row width="100%">
                {loading ? (
                    <Row width="100%" justifyCenter>
                        <Loader />
                    </Row>
                ) : (
                    <OrderContent
                        onDeleteProduct={onDeleteProduct}
                        onCancelOrder={onCancelOrder}
                        onOrderActions={onOrderActions}
                        onUpdate={onUpdate}
                        onExportProduct={onExportProduct}
                        order={data?.order}
                    />
                )}
            </Row>
        </BreadcrumbLayout>
    );
};
