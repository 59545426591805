import { buttonsModal, closeButtonsModal, openButtonsModal, setButtonsModal } from '@/stores/modals/buttonsModal';
import {
    closeConfirmationModal,
    confirmationModal,
    openConfirmationModal,
    setConfirmationModal
} from '@/stores/modals/confirmationModal';
import { closeModal, modal, openModal, setModal } from '@/stores/modals/modal';

export const modalStores = {
    buttonsModal,
    confirmationModal,
    modal
};

export const modalEvents = {
    openConfirmationModal,
    closeConfirmationModal,
    setConfirmationModal,
    openButtonsModal,
    closeButtonsModal,
    setButtonsModal,
    openModal,
    closeModal,
    setModal
};
