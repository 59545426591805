import { ColumnOriginType } from '@C/components/common/ui/tables/Table/types';
import { OrderBoardTableData } from '@/components/tables/OrderBoardTable/types';

export const columns: ColumnOriginType<OrderBoardTableData>[] = [
    { Header: 'Этап', accessor: 'stage' },
    { Header: 'Тип изделия', accessor: 'type' },
    { Header: 'Оборудование', accessor: 'equipment' },
    { Header: 'Прод-ть, ч', accessor: 'stageTime' },
    { Header: 'Старт', accessor: 'startTime' },
    { Header: 'Окончание', accessor: 'endTime' },
    { Header: 'Статус', accessor: 'status' },
    { Header: 'Инфо', accessor: 'info' }
];
